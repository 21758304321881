import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table, Button, Input, notification, Divider, Modal, Select, Avatar, Typography, TableColumnsType, Checkbox, DatePicker, Drawer, Descriptions } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { DeleteOutlined,FileExcelOutlined, FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import FilterReport from "./FilterReport";


const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { RangePicker, MonthPicker } = DatePicker;


 

function Import() {
   

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
 
      const response = await axios.post(`${baseUrl}/dashboard/importData`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Import Data"
          >
            <div className="table-responsive">

            <Col span={12}>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
            </Col>

             
            </div>
          </Card>
        </Col>
      </Row>

    

    </div>
  ); 
}


export default Import;
