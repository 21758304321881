import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import userDummy from "../../assets/images/dummy-user.png";
import PdfImage from "../../assets/images/document.png";
import UserPaymentModalHistory from '../../pages/sn_payments/UserPaymentModalHistory';

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message, notification, Select, Typography, Form, Modal, Image, Tabs
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined, UserOutlined
} from "@ant-design/icons";

import BgProfile from "../../assets/images/bg-profile.jpg";
import profilavatar from "../../assets/images/face-1.jpg";
import convesionImg from "../../assets/images/face-3.jpg";
import convesionImg2 from "../../assets/images/face-4.jpg";
import convesionImg3 from "../../assets/images/face-5.jpeg";
import convesionImg4 from "../../assets/images/face-6.jpeg";
import convesionImg5 from "../../assets/images/face-2.jpg";
import project1 from "../../assets/images/home-decor-1.jpeg";
import project2 from "../../assets/images/home-decor-2.jpeg";
import project3 from "../../assets/images/home-decor-3.jpeg";
import OccupationDetails from "../sn_payments/OccupationDetails";
import FamilyDetails from "../sn_payments/FamilyDetails";
import SocialStatus from "../sn_payments/SocialStatus";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

function View() {

  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [userData, setUserData] = useState({});
  const [multiDocument, setMultiDocument] = useState([])


  useEffect(() => {
    // Check if id exists to determine if it's an update mode
    if (id) {
      fetchUserDetails();
    }
  }, [id]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users/read/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        // setRoleData(response.data.result);
        setUserData(response.data.result);
        setMultiDocument(response.data.result?.uploadDocuments ? JSON.parse(response.data.result?.uploadDocuments) : [])
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };


  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Photo #1",
      title: "Modern",

    },
    {
      img: project2,
      titlesub: "Photo #2",
      title: "Scandinavian",

    },
    {
      img: project3,
      titlesub: "Photo #3",
      title: "Minimalist",

    },
  ];

  return (
    <>


      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>


                <Avatar
                  className="shape-avatar"
                  shape="square"
                  size={80}
                  src={userData.profile_img ? baseUrl + '/' + userData.profile_img : userDummy}
                  icon={!userData.profile_img && <UserOutlined />}
                  onClick={() => showModal(userData.profile_img ? baseUrl + '/' + userData.profile_img : userDummy)}
                  style={{ cursor: 'pointer' }}
                />


                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{userData.name_eng}</h4>
                  <p>{userData.phone_no}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >

            </Col>
          </Row>
        }
      ></Card>

      <div className="tabled">
        <Tabs defaultActiveKey="1" type="card"
          size='default'>
          <TabPane tab="Basic Details" key="1">
            <Row gutter={[24, 0]}>

              <Col span={24} md={8} className="mb-24">
                <Card
                  bordered={false}
                  title={<h6 className="font-semibold m-0">Profile Information</h6>}
                  className="header-solid h-full card-profile-information"
                  bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                  <Descriptions>
                    <Descriptions.Item label="Name" span={3}>

                      {userData.name_eng && userData.name_hn ? `${userData.name_eng} / ${userData.name_hn}` : userData.name_eng || userData.name_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Mobile" span={3}>
                      {userData.phone_no}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email" span={3}>
                      {userData.email_address}
                    </Descriptions.Item>

                    <Descriptions.Item label="Relation" span={3}>

                      {userData.relation_en && userData.relation_hn ? `${userData.relation_en} / ${userData.relation_hn}` : userData.relation_en || userData.relation_hn || ''}
                    </Descriptions.Item>

                    <Descriptions.Item label="Father Name" span={3}>

                      {userData.father_name_en && userData.father_name_hn ? `${userData.father_name_en} / ${userData.father_name_hn}` : userData.father_name_en || userData.father_name_hn || ''}
                    </Descriptions.Item>

                    <Descriptions.Item label="DOB" span={3}>

                      {userData.dob ? moment(userData.dob).format('DD/MM/YYYY') : 'Date of birth not provided'}

                    </Descriptions.Item>
                    <Descriptions.Item label="Maritial Status" span={3}>
                      {userData.maritial_status}
                    </Descriptions.Item>

                    <Descriptions.Item label="Caste" span={3}>
                      {userData.caste}
                    </Descriptions.Item>

                    <Descriptions.Item label="Gotra" span={3}>
                      {userData.gotra}
                    </Descriptions.Item>

                    <Descriptions.Item label="Kuldevi" span={3}>
                      {userData.kuldevi}
                    </Descriptions.Item>

                    <Descriptions.Item label="Kulrishi" span={3}>
                      {userData.kulrishi}
                    </Descriptions.Item>

                    <Descriptions.Item label="Alt Phone no" span={3}>
                      {userData.alternate_phone_no}
                    </Descriptions.Item>
                    <Descriptions.Item label="Alt Phone no 2" span={3}>
                      {userData.alternate_phone_no2}
                    </Descriptions.Item>
                    <Descriptions.Item label="Joining Date" span={3}>
                      {userData.createdAt ? moment(userData.createdAt).format('DD MMMM YYYY') : ''}
                    </Descriptions.Item>

                    <Descriptions.Item label="PAN Card Number" span={3}>
                      {userData.pan_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="Aadhar Number" span={3}>
                      {userData.aadhar_no}
                    </Descriptions.Item>


                    <Descriptions.Item label="Social" span={3}>
                      <a href={userData.twitter_url} className="mx-5 px-5">
                        {<TwitterOutlined />}
                      </a>
                      <a href={userData.facebook_url} className="mx-5 px-5">
                        {<FacebookOutlined style={{ color: "#344e86" }} />}
                      </a>
                      <a href={userData.instagram_url} className="mx-5 px-5">
                        {<InstagramOutlined style={{ color: "#e1306c" }} />}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Remark" span={3}>
                    {userData.remark}
                  </Descriptions.Item>
                  </Descriptions>
                  
                </Card>
              </Col>

              <Col span={24} md={8} className="mb-24">
                <Card
                  bordered={false}
                  title={<h6 className="font-semibold m-0">Permanent Address</h6>}
                  className="header-solid h-full card-profile-information"
                  bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                  <Descriptions>
                    <Descriptions.Item label="Street/Building/House No." span={3}>

                      {userData.per_address_en && userData.per_address_hn ? `${userData.per_address_en} / ${userData.per_address_hn}` : userData.per_address_en || userData.per_address_hn || ''}

                    </Descriptions.Item>
                    <Descriptions.Item label="Village" span={3}>

                      {userData.per_village_en && userData.per_address_hn ? `${userData.per_village_en} / ${userData.per_village_hn}` : userData.per_village_en || userData.per_village_hn || ''}

                    </Descriptions.Item>
                    <Descriptions.Item label="Post" span={3}>

                      {userData.per_post_en && userData.per_post_hn ? `${userData.per_post_en} / ${userData.per_post_hn}` : userData.per_post_en || userData.per_post_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tahsil" span={3}>

                      {userData.per_tahsil_en && userData.per_tahsil_hn ? `${userData.per_tahsil_en} / ${userData.per_tahsil_hn}` : userData.per_tahsil_en || userData.per_tahsil_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Pin Code" span={3}>

                      {userData.per_pin_code_en}
                    </Descriptions.Item>
                    <Descriptions.Item label="District" span={3}>

                      {userData.per_district_en && userData.per_district_hn ? `${userData.per_district_en} / ${userData.per_district_hn}` : userData.per_district_en || userData.per_district_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="State" span={3}>
                      {userData.per_state_en && userData.per_state_hn ? `${userData.per_state_en} / ${userData.per_state_hn}` : userData.per_state_en || userData.per_state_hn || ''}
                    </Descriptions.Item>

                    <Descriptions.Item label="Country" span={3}>
                      {userData.per_country_en && userData.per_country_hn ? `${userData.per_country_en} / ${userData.per_country_hn}` : userData.per_country_en || userData.per_country_hn || ''}
                    </Descriptions.Item>

                  </Descriptions>
                </Card>
              </Col>

              <Col span={24} md={8} className="mb-24">
                <Card
                  bordered={false}
                  title={<h6 className="font-semibold m-0">Temporary Address/अस्थाई पता </h6>}
                  className="header-solid h-full card-profile-information"
                  bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                  <Descriptions>
                    <Descriptions.Item label="House No/Flat No/Building Name" span={3}>

                      {userData.tem_address_en && userData.tem_address_hn ? `${userData.tem_address_en} / ${userData.tem_address_hn}` : userData.tem_address_en || userData.tem_address_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Town/City/Ward" span={3}>

                      {userData.tem_street_en && userData.tem_street_hn ? `${userData.tem_street_en} / ${userData.tem_street_hn}` : userData.tem_street_en || userData.tem_street_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="City" span={3}>

                      {userData.tem_city_en && userData.tem_city_hn ? `${userData.tem_city_en} / ${userData.tem_city_hn}` : userData.tem_city_en || userData.tem_city_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="District" span={3}>

                      {userData.tem_district_en && userData.tem_district_hn ? `${userData.tem_district_en} / ${userData.tem_district_hn}` : userData.tem_district_en || userData.tem_district_hn || ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Pin Code" span={3}>
                      {userData.tem_pin_code_hn}
                    </Descriptions.Item>


                    <Descriptions.Item label="State" span={3}>

                      {userData.tem_state_en && userData.tem_state_hn ? `${userData.tem_state_en} / ${userData.tem_state_hn}` : userData.tem_state_en || userData.tem_state_hn || ''}
                    </Descriptions.Item>

                    <Descriptions.Item label="Country" span={3}>

                      {userData.tem_country_en && userData.tem_country_hn ? `${userData.tem_country_en} / ${userData.tem_country_hn}` : userData.tem_country_en || userData.tem_country_hn || ''}
                    </Descriptions.Item>


                  </Descriptions>
                </Card>
              </Col>

            </Row>
            <Card
              bordered={false}
              className="header-solid mb-24"
              title={
                <>
                  <h6 className="font-semibold">Document</h6>
                  <p>Photo,Identify,Business document</p>
                </>
              }
            >
              <Row gutter={[24, 24]}>
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                  }}
                >
                  {multiDocument.map((item, i) => {
                    const isPdf = item.endsWith('.pdf');
                    if (isPdf) {
                      return (
                        <div key={i} style={{ width: 75, height: 75, position: 'relative' }}>
                          <a
                            href={`${baseUrl}/${item}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10 }}
                          >
                            <img
                              src={PdfImage} // Replace with a suitable thumbnail image path
                              alt="PDF thumbnail"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </a>
                        </div>
                      );
                    } else {
                      return (
                        <Image
                          key={i}
                          width={75}
                          src={`${baseUrl}/${item}`}
                        />
                      );
                    }
                  })}
                </Image.PreviewGroup>


                {/* {project.map((p, index) => (
            <Col span={24} md={12} xl={6} key={index}>
              <Card
                bordered={false}
                className="card-project"
                cover={<img alt="example" src={p.img} />}
              >
                <div className="card-tag">{p.titlesub}</div>
                <h5>{p.titile}</h5>
                <p>{p.disciption}</p>

              </Card>
            </Col>
          ))} */}

              </Row>
            </Card>

          </TabPane>

          <TabPane tab="Payment Details" key="2">
            <Card>
              <UserPaymentModalHistory userid={id} />
            </Card>
          </TabPane>

          <TabPane tab="Occupation/Bussiness details" key="3">
            <Card>
              <OccupationDetails user_id={id} />
            </Card>
          </TabPane>
          <TabPane tab="Family Details" key="4">
            <Card>
              <FamilyDetails user_id={id} />
            </Card>
          </TabPane>
          <TabPane tab="Social Status" key="5">
            <Card>
              <SocialStatus user_id={id} />
            </Card>
          </TabPane>
        </Tabs>
      </div>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>
    </>
  );
}

export default View;
