 
 


   //const serverApi = `http://localhost:3000`; 
  //const serverApi = `http://3.111.60.21:3000`; 
   const serverApi = `https://api.abrsvs.com`;

 

  
   
export const baseUrl = serverApi;     