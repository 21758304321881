import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table, Button, Input, AutoComplete, notification, Modal, Select, Avatar, Typography, TableColumnsType, Checkbox, DatePicker, Drawer } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { DeleteOutlined, FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
 

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "Phone no",
    dataIndex: "phone_no",
    key: "phone_no",
    fixed: 'left',
    width: 150,
  },
  {
    title: "Message Type",
    dataIndex: "message_type",
    key: "message_type",
  },
  {
    title: 'Send Status',
    dataIndex: 'send_status',
    key: 'send_status',
  },
  {
    title: "Date/Time",
    dataIndex: "timestamp",
    key: "timestamp",
  },
  {
    title: "Response",
    dataIndex: "response",
    key: "response"
  },    
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function MessageLogList() {
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    createdBy: ''
  });

  useEffect(() => {
    getUserList();
  }, []);

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 10, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/message-log/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/message-log/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Message Log deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleSearch = value => {
    setSearch(value);
    getUserList(currentPage, limit, sortField, sortOrder, value, filters);
  };

  const handleLimitChange = value => {
    setLimit(value);
    getUserList(1, value, sortField, sortOrder, search, filters);
  };

  const debouncedGetUserList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {
    getUserList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);



  useEffect(() => {
    const filterKeys = Object.keys(filters);

    // Check if any filter other than 'createdBy' has a length of 3 or more
    const anyFilterHasMinLength = filterKeys.some(
      key => key !== 'createdBy' && filters[key].length >= 3
    );

    // Check if all filters are either empty or specifically 'createdBy'
    const allFiltersEmptyOrCreatedBy = filterKeys.every(
      key => key === 'createdBy' || !filters[key]
    );



    if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {

      debouncedGetUserList(currentPage, limit, sortField, sortOrder, search, filters);
    }
  }, [filters, currentPage, limit, sortField, sortOrder, search]);

  const handleFilterChange = (field, value) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };



  const [showUserFilter, setShowUserFilter] = useState(true);
  const [showPermanentAddress, setShowPermanentAddress] = useState(false);
  const [showTemporaryAddress, setShowTemporaryAddress] = useState(false);

  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);
    if (staffList.length === 0) {
      getStaffList();
    }
  };

  const handlePermanentAddressCheckboxChange = (e) => {
    setShowPermanentAddress(e.target.checked);
  };

  const handleTemporaryAddressCheckboxChange = (e) => {
    setShowTemporaryAddress(e.target.checked);
  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userBasicDetail, setuserBasicDetail] = useState({});  // state to store the selected file

  const [AddEditUserDetailsForm, setAddEditUserDetailsForm] = useState(null);


  const AddUserDetails = async (userId) => {

    setAddUserDetailsModel(true)

    const response = await axios.post(
      `${baseUrl}/payments/EditUserDetail`,
      { userId: userId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      }
    );
    if (response.status === 200 && response.data.exists) {

      setAddEditUserDetailsForm(response.data.details);
      setuserBasicDetail({})



    } else {
      // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

    }




  }
  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
  }

  // Get Suggestion Values
  const [optionSuggestion, setOptionSuggestion] = useState([]);
  const getPanelValue = async (searchText, columnName, tableName) => {
    if (searchText.length < 3) {
      setOptionSuggestion([]);
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
        params: { q: searchText, column: columnName, table: tableName },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        setOptionSuggestion(response.data.result);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Message Log List"
           >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                     
                        <>
                          <Col span={4}> <Input placeholder="Phone no" value={filters.phone_no} onChange={e => handleFilterChange('phone_no', e.target.value)} /></Col>

                          <Col span={4} ><Select style={{ width: '100%' }} placeholder="Message Type" value={filters.message_type} onChange={value => handleFilterChange('message_type', value)}>
                          <Option value="">Select</Option>
                            <Option value="Birthday SMS">Birthday SMS</Option>
                            <Option value="Suggestion on Birthday">Suggestion on Birthday</Option>
                            <Option value="Pending Payment Day">Pending Payment Day</Option>
                            <Option value="Payment Receipt">Payment Receipt</Option>
                          </Select>
                          </Col>

                          <Col span={4} ><Select style={{ width: '100%' }} placeholder="Send Status" value={filters.send_status} onChange={value => handleFilterChange('send_status', value)}>
                          <Option value="">Select</Option>
                            <Option value="Success">Success</Option>
                            <Option value="Failure">Failure</Option> 
                          </Select>
                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.timestamp ? moment(filters.timestamp, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('timestamp', dateString)} placeholder="Date"
                            />
                          </Col>

 

                        </>
                     

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={10} onChange={handleLimitChange}>
                          <Option value={10}>10</Option>
                          <Option value={20}>20</Option>
                          <Option value={30}>30</Option>
                          <Option value={40}>40</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}


                dataSource={userList.map((user, index) => {

                  

                  return {
                    key: (limit * (currentPage - 1)) + index + 1,
                    phone_no: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.phone_no ? user.phone_no : '-'}</Title>
                        </div>
                      </>
                    ),
                  
                 
                    message_type: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.message_type}</Title>
                        </div>
                      </>
                    ),
                    send_status: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.send_status}  </Title>
                        </div>
                      </>
                    ),
                    timestamp: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.timestamp ? moment(user.timestamp).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),
                    
                    response: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.response}  </Title>
                        </div>
                      </>
                    ),
                    
                    action: (
                      <div className="button-container"> 
                        {hasPermission('deletePermission', 'Delete Log') && (
                          <button onClick={() => showDeleteConfirm(user.log_id)} className="delete-btn">
                            <DeleteOutlined />
                          </button>
                        )}
                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

    



    </div>
  );
}


export default MessageLogList;
