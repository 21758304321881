import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import RoleList from "./pages/role/List";
import RoleForm from "./pages/role/Form";
import StaffList from "./pages/staff/List";
import StaffForm from "./pages/staff/Form";

import UsersList from "./pages/sn_users/List";
import UsersForm from "./pages/sn_users/Form";
import UsersView from "./pages/sn_users/View";

import SuspenseList from "./pages/sn_suspense_entries/List";
import SuspenseForm from "./pages/sn_suspense_entries/Form";
import SuspenseView from "./pages/sn_suspense_entries/View";

import RegistrationList from "./pages/sn_registration/List";
import RegistrationForm from "./pages/sn_registration/Form";

import MessageLogList from "./pages/sn_message_logs/List";

import PaymentsList from "./pages/sn_payments/List";
import PaymentsForm from "./pages/sn_payments/Form";
import PaymentsView from "./pages/sn_payments/ViewPayments"; 
import AddUserPayment from "./pages/sn_payments/AddUserPayment"; 
import EditUserPayment from "./pages/sn_payments/EditUserPayment"; 
import TrackingList from "./pages/sn_tracking/List";

import ManualReport from "./pages/sn_reports/ManualReport";
import BirthdayReport from "./pages/sn_reports/BirthdayReport";
import CallingReport from "./pages/sn_reports/CallingReport";
import ImportData from "./pages/sn_reports/ImportData";


 


function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Main>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />
          
          {/* Role */}
          <Route exact path="/role" component={RoleList} />
          <Route exact path="/role/add" component={RoleForm} />
          <Route exact path="/role/update/:id" component={RoleForm} />
           {/* Staff */}
          <Route exact path="/staff" component={StaffList} />
          <Route exact path="/staff/add" component={StaffForm} />
          <Route exact path="/staff/update/:id" component={StaffForm} />

          <Route exact path="/users" component={UsersList} />
          <Route exact path="/users/add" component={UsersForm} />
          <Route exact path="/users/update/:id" component={UsersForm} />
          <Route exact path="/users/view/:id" component={UsersView} />
          
          <Route exact path="/payments-history" component={PaymentsList} />
          <Route exact path="/payments/add" component={PaymentsForm} />
          <Route exact path="/payments/update/:id" component={EditUserPayment} />
          <Route exact path="/payments/view/:id" component={PaymentsView} />
          <Route exact path="/payments/add_user_payment" component={AddUserPayment} />
          <Route exact path="/tracking" component={TrackingList} />

          <Route exact path="/suspense-entries" component={SuspenseList} />
          <Route exact path="/suspense-entries/add" component={SuspenseForm} />
          <Route exact path="/suspense-entries/update/:id" component={SuspenseForm} />
          <Route exact path="/suspense-entries/view/:id" component={SuspenseView} />

          <Route exact path="/registration" component={RegistrationList} />
          <Route exact path="/registration/add" component={RegistrationForm} />
          <Route exact path="/registration/update/:id" component={RegistrationForm} />

          <Route exact path="/message-log" component={MessageLogList} /> 

          <Route exact path="/reports/manual-report" component={ManualReport} />
          <Route exact path="/reports/birthday-list" component={BirthdayReport} />
          <Route exact path="/reports/calling-report" component={CallingReport} />
          <Route exact path="/reports/import" component={ImportData} />

   
        </Main>
      </Switch>
    </div>
  );
}

export default App;
