// permissions.js
export function getPermissions() {
    const storedPermission = localStorage.getItem('permission');
    return storedPermission ? JSON.parse(storedPermission) : {};
}

export function hasPermission(permissionType, permissionName) {
    const storedPermission = localStorage.getItem('permission');
    const permissions = storedPermission ? JSON.parse(storedPermission) : {};
    
    if (permissions === 'all') {
        return true;
    }
    const perm = permissions[permissionType];
    return perm && perm.includes(permissionName);
}
