import { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import moment from 'moment';
import axios from "axios";
import { Row, Col, Button, Space, notification, Form, Input, Select, Table, Collapse, Modal, DatePicker, AutoComplete } from "antd";
import '../../assets/styles/custom_style.css';
import { CalendarOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Item } = Form;
const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;

const FamilyDetails = ({ user_id }) => {
    console.log("user_id", user_id);
    const [form] = Form.useForm();
    const [recordId, setRecordId] = useState(null);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isTableVisible, setIsTableVisible] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    useEffect(() => {
        setUserData([]);
        setIsTableVisible(false);
        // Fetch user data or any other initial data here
        if (user_id) {
            fetchOccupationData(user_id);
        }
    }, [user_id]);

    async function fetchOccupationData(user_id) {
        try {
            const response = await axios.get(`${baseUrl}/family/list/${user_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.data.status) {
                setUserData(response.data.result);
                setIsTableVisible(response.data?.result?.length === 0 ? false : true)
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {
            // Check if at least one field is filled
            const isEmpty = Object.values(values).every(x => x === null || x === '' || x === undefined);

            if (isEmpty) {
                notification.info({
                    message: 'Info',
                    description: "Please fill out at least one field.",
                    placement: 'topRight'
                });
                return; // Exit the function if no fields are filled
            }
            
            if (user_id) {
                values.user_id = user_id;

                let response;
                if (isUpdateMode) {
                    response = await axios.patch(`${baseUrl}/family/update/${recordId}`, values, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                } else {
                    response = await axios.post(`${baseUrl}/family/create`, values, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                }

                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: isUpdateMode ? 'Family member updated successfully!' : 'Family member added successfully!',
                        placement: 'topRight'
                    });
                    setIsTableVisible(true);
                    fetchOccupationData(user_id); // Assuming you have a fetch function for social status data
                    form.resetFields(); // Reset form fields after successful submission
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                notification.info({
                    message: 'Info',
                    description: "Please add basic information",
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const handleEdit = (record) => {
        form.setFieldsValue({
            m_name: record.m_name,
            m_relation_type: record.m_relation_type,
            m_relation_name: record.m_relation_name,
            m_gender: record.m_gender,
            m_dob: record.m_dob ? moment(record.m_dob) : null,
            m_age: record.m_age,
            m_married_status: record.m_married_status,
            m_education: record.m_education,
            m_occupation: record.m_occupation,
            m_current_address: record.m_current_address,
            m_phone_no: record.m_phone_no,
            m_email: record.m_email,
        });
        setRecordId(record.id); // Assuming id is available in the record object
        setIsUpdateMode(true);
        setIsTableVisible(false); // Hide table and show form for editing
    };

    const showDeleteConfirm = (record) => {
        confirm({
            title: 'Are you sure you want to delete family member?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleDelete = async (record) => {
        try {
            const response = await axios.delete(`${baseUrl}/family/delete/${record.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Occupation deleted successfully!',
                    placement: 'topRight'
                });
                fetchOccupationData(user_id); // Refresh table data after deletion
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'm_name',
            key: 'name',
        },
        {
            title: 'Relation',
            dataIndex: 'm_relation_type',
            key: 'relation',
        },
        {
            title: 'Relation With',
            dataIndex: 'm_relation_name',
            key: 'relation_with',
        },
        {
            title: 'Gender',
            dataIndex: 'm_gender',
            key: 'gender',
        },
        {
            title: 'DOB',
            dataIndex: 'm_dob',
            key: 'dob',
            render: (text) => (text ? moment(text).format('YYYY-MM-DD') : 'N/A'), // Format date
        },
        {
            title: 'Age',
            dataIndex: 'm_age',
            key: 'age	',
        },
        {
            title: 'Married Status',
            dataIndex: 'm_married_status',
            key: 'married_status',
        },
        {
            title: 'Education',
            dataIndex: 'm_education',
            key: 'm_education',
        },
        {
            title: 'Occupation',
            dataIndex: 'm_occupation',
            key: 'm_occupation',
        },
        {
            title: 'Current Address',
            dataIndex: 'm_current_address',
            key: 'm_current_address',
        },
        {
            title: 'Phone Number',
            dataIndex: 'm_phone_no',
            key: 'm_phone_no',
        },
        {
            title: 'Email',
            dataIndex: 'm_email',
            key: 'm_email',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div className="button-container">
                    <Space size="middle">
                        <button className="update-btn" onClick={() => handleEdit(record)}> <EditOutlined /></button>
                        <button className="delete-btn" onClick={() => showDeleteConfirm(record)}><DeleteOutlined /></button>
                    </Space>
                </div>
            ),
        },
        // Add more columns as needed
    ];


    const [optionSuggestion, setOptionSuggestion] = useState([]);

    const getPanelValue = async (searchText, columnName, tableName) => {
        if (searchText.length < 3) {
            setOptionSuggestion([]);
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
                params: { q: searchText, column: columnName, table: tableName },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                setOptionSuggestion(response.data.result);
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChangeSuggestion = (value, columnName) => {
        form.setFieldsValue({
            [columnName]: value
        });
    };

    const changeTableView = async () => {
        if (isTableVisible) {
            setIsTableVisible(!isTableVisible)
            form.setFieldsValue({
                m_name: "",
                m_relation_type: "",
                m_relation_name: "",
                m_gender: "",
                m_dob: "",
                m_age: "",
                m_married_status: "",
                m_education: "",
                m_occupation: "",
                m_current_address: "",
                m_phone_no: "",
                m_email: "",
            });
            setRecordId(null); // Assuming id is available in the record object
            setIsUpdateMode(false);
        } else {
            setIsTableVisible(!isTableVisible)
            // setIsTableVisible(false); // Hide table and show form for editing
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Button
                        type="primary"
                        onClick={() => changeTableView()}
                        style={{ marginBottom: '20px', float: 'right' }}
                    >
                        {isTableVisible ? 'Add' : 'Show Table'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="tabled" >

                        {isTableVisible ? (
                            <Table
                                columns={columns}
                                dataSource={userData}
                                pagination={false}
                                scroll={{ x: true }}
                            />
                        ) : (
                            <Form style={{ "padding": "0px" }} form={form} onFinish={onFinish} layout="vertical">
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Name" name="m_name">
                                            <Input placeholder="Enter full name" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Relation" name="m_relation_type">
                                            <Select placeholder="Select relation">
                                                <Option value="Father">Father</Option>
                                                <Option value="Mother">Mother</Option>
                                                <Option value="Son">Son</Option>
                                                <Option value="Daughter">Daughter</Option>
                                                <Option value="Brother">Brother</Option>
                                                <Option value="Sister">Sister</Option>
                                                <Option value="Grandfather">Grandfather</Option>
                                                <Option value="Grandmother">Grandmother</Option>
                                                <Option value="Grandson">Grandson</Option>
                                                <Option value="Granddaughter">Granddaughter</Option>
                                                <Option value="Uncle">Uncle</Option>
                                                <Option value="Aunt">Aunt</Option>
                                                <Option value="Nephew">Nephew</Option>
                                                <Option value="Niece">Niece</Option>
                                                <Option value="Cousin">Cousin</Option>
                                                <Option value="Father-in-law">Father-in-law</Option>
                                                <Option value="Mother-in-law">Mother-in-law</Option>
                                                <Option value="Son-in-law">Son-in-law</Option>
                                                <Option value="Daughter-in-law">Daughter-in-law</Option>
                                                <Option value="Brother-in-law">Brother-in-law</Option>
                                                <Option value="Sister-in-law">Sister-in-law</Option>
                                                <Option value="Stepfather">Stepfather</Option>
                                                <Option value="Stepmother">Stepmother</Option>
                                                <Option value="Stepson">Stepson</Option>
                                                <Option value="Stepdaughter">Stepdaughter</Option>
                                                <Option value="Half-brother">Half-brother</Option>
                                                <Option value="Half-sister">Half-sister</Option>
                                                <Option value="Godfather">Godfather</Option>
                                                <Option value="Godmother">Godmother</Option>
                                                <Option value="Godson">Godson</Option>
                                                <Option value="Goddaughter">Goddaughter</Option>
                                            </Select>
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Relation with" name="m_relation_name">
                                            <Input placeholder="Enter relation with" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Gender" name="m_gender">
                                            <Select placeholder="Select gender">
                                                <Option value="Male">Male</Option>
                                                <Option value="Female">Female</Option>
                                                <Option value="Other">Other</Option>
                                            </Select>
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="DOB" name="m_dob" >
                                            <DatePicker className="filter_dob" format="DD-MM-YYYY" placeholder="Select Date of Birth" prefix={<CalendarOutlined />} />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Age" name="m_age">
                                            <Input type="number" placeholder="Enter age" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Married Status" name="m_married_status">
                                            <Select placeholder="Select married status">
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                            </Select>
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Education" name="m_education">
                                            <AutoComplete placeholder="Education"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "m_education")}
                                                onSearch={(text) => getPanelValue(text, "m_education", "sn_family_members")}
                                            />
                                        </Item>
                                    </Col>



                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Occupation" name="m_occupation">
                                            <AutoComplete placeholder="Occupation"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "m_occupation")}
                                                onSearch={(text) => getPanelValue(text, "m_occupation", "sn_family_members")}
                                            />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Current Address" name="m_current_address">
                                            <Input placeholder="Enter current address" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Phone Number" name="m_phone_no">
                                            <Input placeholder="Enter phone number" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Email" name="m_email">
                                            <Input type="email" placeholder="Enter email" />
                                        </Item>
                                    </Col>
                                </Row>
                                <div className="user_add_form_details" >
                                    <Space>
                                        <Button type="primary" onClick={() => form.submit()}>Save Detail</Button>
                                    </Space>
                                </div>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default FamilyDetails;
