import React, { useState } from 'react';
import { Form, Input, Upload, Button, Avatar, message, Modal, Card } from 'antd';
import { UserOutlined, UploadOutlined } from '@ant-design/icons';
import { baseUrl } from '../config';
import axios from 'axios';

const { confirm } = Modal;

const Profile = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const [file, setFile] = useState(null);  // state to store the selected file
    const [form] = Form.useForm();

    const handleImageChange = (e) => {
        // Handle file selection here
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader(); 
        reader.onload = (event) => {
            setImageUrl(event.target.result);
        };
        reader.readAsDataURL(file);
        message.info(`Selected file: ${file.name}`);
    };

    const handleButtonClick = () => {
        // Programmatically trigger the input file selection
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleFormSubmit = async values => {
        const formData = new FormData();
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('email', values.email);
        if (file) {
            formData.append('file', file);  // append the file to form data
        }
        const id = localStorage.getItem('userId');
        const response = await axios.patch(`${baseUrl}/staff-profile/update/${id}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
        });
        if (response.status == 200) {
            // Simulate an API request
            console.log('Form Data:', formData);
            localStorage.setItem('firstName', response.data.result.firstName);
            localStorage.setItem('lastName', response.data.result.lastName === undefined ? '' : response.data.result.lastName)
            localStorage.setItem('email', response.data.result.email);
            if (response.data.result.image) {
                localStorage.setItem('photo', response.data.result.image);
            }
            message.success('Profile updated successfully!');
        } else {
            message.error(response.data.message);
        }

    };

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Profile"
        >
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                    initialValues={{
                        firstName: localStorage.getItem('firstName') || '',
                        lastName: localStorage.getItem('lastName') || '',
                        email: localStorage.getItem('email') || '',
                    }}
                >
                    <Form.Item style={{ "marginTop": 25 }}>
                        <div className="upload-container">
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                            <div>
                                <Avatar
                                    size={80}
                                    icon={<UserOutlined />}
                                    src={imageUrl ? imageUrl : baseUrl + '/' + localStorage.getItem('photo')}
                                    style={{ marginBottom: 16 }}
                                />
                                <div>
                                    <Button icon={<UploadOutlined />} onClick={handleButtonClick}>
                                        Upload
                                    </Button>
                                </div>
                            </div>
                            {/* )} */}
                            {/* </Upload> */}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'Please enter a valid email!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update Profile
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Card>
    );
};

export default Profile;
