import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Badge, Card, Col, Dropdown, notification, Row, Space, Table } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../config';
import moment from 'moment';

const items = [
    {
        key: '1',
        label: 'Action 1',
    },
    {
        key: '2',
        label: 'Action 2',
    },
];
const TrackingList = () => {
    const [trackingList, setTrackingList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        name: '',
        description: '',
        status: ''
    });
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [expandedData, setExpandedData] = useState({});

    useEffect(() => {
        getTrackingList();
    }, []);

    async function getTrackingList(page = 1, limit = 10, sortField = null, sortOrder = null, search = '', filters = {}) {
        try {
            setCurrentPage(page);
            setLimit(limit);
            const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
            const searchParam = search ? `&search=${search}` : '';
            const filterParams = Object.keys(filters)
                .map(key => `${key}=${filters[key]}`)
                .join('&');
            const response = await axios.get(`${baseUrl}/tracking/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.data.status) {
                setTrackingList(response.data.result);
                setPagination({
                    current: page,
                    pageSize: limit,
                    total: response.data.totalCount
                });
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const handleExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record.key]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
        }
    };


    const expandedRowRender = (record) => {
        const columns = [
            // {
            //     title: 'Date',
            //     dataIndex: 'date',
            //     key: 'date',
            // },
            {
                title: 'Column',
                dataIndex: 'column',
                key: 'column',
            },
            {
                title: 'Old Value',
                dataIndex: 'oldValue',
                key: 'oldValue',
            },
            {
                title: 'New Value',
                dataIndex: 'newValue',
                key: 'newValue',
            },
            // {
            //     title: 'Action',
            //     key: 'operation',
            //     render: () => (
            //         <Space size="middle">
            //             <a>Pause</a>
            //             <a>Stop</a>
            //             <Dropdown
            //                 menu={{
            //                     items,
            //                 }}
            //             >
            //                 <a>
            //                     More <DownOutlined />
            //                 </a>
            //             </Dropdown>
            //         </Space>
            //     ),
            // },
        ];
        console.log(record);
        // Parse changed_data if it's a JSON string, otherwise, use it directly
        let data = [];
        try {
            data = typeof record.changed_data === 'string' ? JSON.parse(record.changed_data) : record.changed_data;
        } catch (error) {
            console.error('Error parsing changed_data:', error);
            data = [];
        }

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };




    const columns = [
        {
            title: 'Module Name',
            dataIndex: 'module_name',
            key: 'module_name',
        },
        {
            title: 'Operation Type',
            dataIndex: 'operation_type',
            key: 'operation_type',
        },
        {
            title: 'Action By',
            dataIndex: 'action_by',
            key: 'action_by',
        },
        // {
        //     title: 'Record',
        //     dataIndex: 'record_id',
        //     key: 'record_id',
        // },
        {
            title: 'Date',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        // {
        //     title: 'Action',
        //     key: 'operation',
        //     render: () => <a>Publish</a>,
        // },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
        setSortField(field);
        setSortOrder(formattedOrder);
        setFilters(filters);
        getTrackingList(current, pageSize, field, formattedOrder, search, filters);
    };

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title="Tracking"
                        extra=""
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                expandable={{
                                    expandedRowRender,
                                    expandedRowKeys: expandedRowKeys,
                                    onExpand: handleExpand,
                                }}
                                dataSource={trackingList.map((user, index) => ({
                                    key: index.toString(),
                                    module_name: user.module_name,
                                    operation_type: user.operation_type,
                                    action_by: user.firstName + ' ' + user.lastName,
                                    // record_id: user.record_id,
                                    timestamp: user.timestamp ? moment(user.timestamp).format('DD-MM-YYYY hh:mm:ss A') : '-',
                                    changed_data: JSON.parse(user.changed_data)
                                }))}
                                pagination={{
                                    ...pagination,
                                    showTotal: total => `Total ${total} records`,
                                    style: { marginRight: 20 }
                                }}
                                onChange={handleTableChange}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
export default TrackingList;