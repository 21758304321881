import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card,Form, Table, Button, Input, AutoComplete, notification, Modal, Select, Avatar, Typography, TableColumnsType, Checkbox, DatePicker, Drawer } from "antd";
import axios from "axios"; 
import { baseUrl } from "../../config";
import { DeleteOutlined,CalendarOutlined, FilterOutlined,FileExcelOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import AddUserDetailsModel from '../sn_payments/AddUserDetailsModel';
import * as XLSX from 'xlsx';
import { useHistory, useParams } from "react-router-dom";

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;
const { TextArea } = Input;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "Reciept no",
    dataIndex: "reciept_no",
    key: "reciept_no",
    fixed: 'left',
    width: 150,
  },
  {
    title: "Txn Date",
    dataIndex: "txn_date",
    key: "txn_date",
  },
  {
    title: 'Tnx Detail (UTR/ID)',
    dataIndex: 'tnx_detail',
    key: 'tnx_detail',
  },
  {
    title: "Ref No./Cheque No.",
    dataIndex: "cheque_no",
    key: "cheque_no",
  },
  {
    title: "Branch Code.",
    dataIndex: "branch_code",
    key: "branch_code"
  },
  {
    title: "Amount(Credit)",
    dataIndex: "amount",
    key: "amount",
  },


  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "Entry Date",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function SuspenseEntryList() {
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    createdBy: ''
  });
  const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
 
  const [sendMessage, setSendMessage] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [txnDate, setTxnDate] = useState(moment().format('YYYY-MM-DD'));

  const toggleFormVisibility = () => {
    setAddForm(prevState => !prevState); // Toggle between true and false
  };

  useEffect(() => {
    getUserList();
  }, []);

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/suspense-entry/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/suspense-entry/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Suspense Entry deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleSearch = value => {
    setSearch(value);
    getUserList(currentPage, limit, sortField, sortOrder, value, filters);
  };

  const handleLimitChange = value => {
    setLimit(value);
    getUserList(1, value, sortField, sortOrder, search, filters);
  };

  const debouncedGetUserList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {
    getUserList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);



  useEffect(() => {
    const filterKeys = Object.keys(filters);

    // Check if any filter other than 'createdBy' has a length of 3 or more
    const anyFilterHasMinLength = filterKeys.some(
      key => key !== 'createdBy' && filters[key].length >= 3
    );

    // Check if all filters are either empty or specifically 'createdBy'
    const allFiltersEmptyOrCreatedBy = filterKeys.every(
      key => key === 'createdBy' || !filters[key]
    );



    if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {

      debouncedGetUserList(currentPage, limit, sortField, sortOrder, search, filters);
    }
  }, [filters, currentPage, limit, sortField, sortOrder, search]);

  const handleFilterChange = (field, value) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };



  const [showUserFilter, setShowUserFilter] = useState(true);
  const [showPermanentAddress, setShowPermanentAddress] = useState(false);
  const [showTemporaryAddress, setShowTemporaryAddress] = useState(false);

  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);
    if (staffList.length === 0) {
      getStaffList();
    }
  };

  const handlePermanentAddressCheckboxChange = (e) => {
    setShowPermanentAddress(e.target.checked);
  };

  const handleTemporaryAddressCheckboxChange = (e) => {
    setShowTemporaryAddress(e.target.checked);
  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userBasicDetail, setuserBasicDetail] = useState({});  // state to store the selected file

  const [AddEditUserDetailsForm, setAddEditUserDetailsForm] = useState(null);


  const AddUserDetails = async (userId) => {

    setAddUserDetailsModel(true)

    const response = await axios.post(
      `${baseUrl}/payments/EditUserDetail`,
      { userId: userId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      }
    );
    if (response.status === 200 && response.data.exists) {

      setAddEditUserDetailsForm(response.data.details);
      setuserBasicDetail({})



    } else {
      // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

    }




  }
  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
  }

  // Get Suggestion Values
  const [optionSuggestion, setOptionSuggestion] = useState([]);
  const getPanelValue = async (searchText, columnName, tableName) => {
    if (searchText.length < 3) {
      setOptionSuggestion([]);
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
        params: { q: searchText, column: columnName, table: tableName },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        setOptionSuggestion(response.data.result);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const exportToExcel = async () => {
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/suspense-entry/list?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1,
        "Reciept no": user.reciept_no,
        "Txn Date": user.txn_date,
        "Tnx Detail (UTR/ID)": user.tnx_detail,
        "Ref No./Cheque No.": user.cheque_no,   
         "Branch Code": user.branch_code,
        "Amount(Credit)": user.amount,
        "Remark": user.remark,
        "Entry Date":user.createdAt ? moment(user.createdAt).format('DD-MM-YYYY') : '-', 
        "Description": user.description 
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Suspense Entry");
      XLSX.writeFile(workbook, "Suspense-Entry.xlsx");
    } else {
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };


  const onFinish = async (values) => {
 
    try {

      
        if (txnDate) {
            const formattedDate = moment(txnDate).format('YYYY-MM-DD');
            values.txn_date = formattedDate;
        } else {
            values.txn_date = '';
        }

        if (isUpdateMode) {
            const response = await axios.patch(`${baseUrl}/suspense-entry/update/${id}`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Suspense Entry updated successfully!',
                    placement: 'topRight'
                });
                history.push('/suspense-entries');
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } else {
            const response = await axios.post(`${baseUrl}/suspense-entry/create`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Suspense Entry added successfully!',
                    placement: 'topRight'
                });
                form.resetFields();
                getUserList();
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        }
    } catch (error) {
        console.error("API error:", error);
        notification.info({
            message: 'Info',
            description: error.response?.data?.message,
            placement: 'topRight'
        });
    }
};

const handleDateChange = (date, dateString) => {
  if (date) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setTxnDate(formattedDate)
  } else {
    setTxnDate(null);
  }
};



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>


      {addForm && (
          <Col xs="24" xl={24}>
            <div className="tabled">
              <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={isUpdateMode ? "Update Entry" : "Add Entry"}
                  >
                      <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={4} lg={4}>
                                    <Item label="Tnx Date" >
                                    <DatePicker onChange={handleDateChange} value={txnDate && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Txn Date" prefix={<CalendarOutlined />} />

                                    </Item>
                                </Col>

                                <Col xs={24} sm={4} lg={4}>
                                    <Item
                                        label="Ref No./Cheque No."
                                        name="cheque_no"
                                    >
                                        <Input placeholder="Enter Ref No./Cheque No." />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={4} lg={4}>
                                    <Item
                                        label="Branch Code."
                                        name="branch_code"
                                    >
                                        <Input placeholder="Enter Branch Code." />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={6} lg={6}>
                                    <Item
                                        label="Amount(Credit)"
                                        name="amount"
                                        rules={[{ required: true, message: 'Please enter Amount(Credit)' }]}
                                    >
                                        <Input type="number" placeholder="Enter Amount(Credit)" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={6} lg={6}>
                                    <Item
                                        label="Tnx Detail (UTR/ID)"
                                        name="tnx_detail"
                                        rules={[{ required: true, message: 'Please enter Tnx Detail (UTR/ID)' }]}
                                    >
                                        <Input placeholder="Enter Tnx Detail (UTR/ID)" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Description"
                                        name="description"
                                    >
                                        <TextArea placeholder="Enter Description" rows={2} />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Remark"
                                        name="remark"
                                    >
                                        <TextArea placeholder="Enter Remark" rows={2} />
                                    </Item>
                                </Col>

                            </Row>
                            <Row style={{ textAlign: 'right' }}>
                                <Col xs={24} sm={24} lg={24}>
                                    <Item>
                                        <Button type="primary" htmlType="submit">
                                            {isUpdateMode ? "Update" : "Save"}
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

        )}

        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Suspense Entry List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>
                {hasPermission('createPermission', 'Create User') && (
                <button onClick={toggleFormVisibility} className="custom-btn">Add Entry</button>
                )}
                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Excel Download
                </Button>
              </div>
            }

           >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Entry Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Reciept no" value={filters.reciept_no} onChange={e => handleFilterChange('reciept_no', e.target.value)} /></Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.txn_date ? moment(filters.txn_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('txn_date', dateString)} placeholder="Txn Date"
                            />
                          </Col>

                          <Col span={4}><Input placeholder="Ref No./Cheque No." value={filters.cheque_no} onChange={e => handleFilterChange('cheque_no', e.target.value)} /></Col>
                          <Col span={4}> <Input placeholder="Branch Code." value={filters.branch_code} onChange={e => handleFilterChange('branch_code', e.target.value)} /></Col>
                          <Col span={4}><Input placeholder="Amount(Credit)" value={filters.amount} onChange={e => handleFilterChange('amount', e.target.value)} /></Col>

                          <Col span={4}>
                            <Input placeholder="Tnx Detail (UTR/ID)" value={filters.tnx_detail} onChange={e => handleFilterChange('tnx_detail', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Remark" value={filters.remark} onChange={e => handleFilterChange('remark', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.createdAt ? moment(filters.createdAt, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('createdAt', dateString)} placeholder="Entry Date"
                            />
                          </Col>

                        </>
                      )}

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}


                dataSource={userList.map((user, index) => {

                  const isMatch = user.tnx_detail === user.utr_number;

                  return {
                    key: (limit * (currentPage - 1)) + index + 1,
                    reciept_no: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.reciept_no ? user.reciept_no : '-'}</Title>
                        </div>
                      </>
                    ),
                    txn_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.txn_date ? moment(user.txn_date).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),
                    tnx_detail: (
                      <div className="author-info">
                        <Title
                          level={5}
                          style={{
                            textAlign : 'center',
                            color: isMatch ? 'white' : 'inherit',
                            backgroundColor: isMatch ? 'green' : 'inherit',
                            padding: isMatch ? '5px' : 'inherit',
                            borderRadius: isMatch ? '5px' : 'inherit'
                          }}
                        >
                          {user.tnx_detail}
                        </Title>
                      </div>
                    ),
                    cheque_no: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.cheque_no}</Title>
                        </div>
                      </>
                    ),
                    branch_code: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.branch_code}  </Title>
                        </div>
                      </>
                    ),
                    amount: (
                      <>
                        <div className="author-info">
                          <Title level={5}>Rs.{user.amount}  </Title>
                        </div>
                      </>
                    ),
                    
                    remark: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.remark}  </Title>
                        </div>
                      </>
                    ),
                    createdAt: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.createdAt ? moment(user.createdAt).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),
                    description: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.description}  </Title>
                        </div>
                      </>
                    ),

                    action: (
                      <div className="button-container">
                        {hasPermission('editPermission', 'Edit User') && (
                          <Link to={`/suspense-entries/update/${user.id}`} className="update-btn">
                            <EditOutlined />
                          </Link>
                        )}

                        {hasPermission('deletePermission', 'Delete User') && (
                          <button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                            <DeleteOutlined />
                          </button>
                        )}
                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

      <Drawer
        title="User Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >


        <AddUserDetailsModel userData={AddEditUserDetailsForm} userBasicDetail={userBasicDetail} primaryId='' onIdChange='' />

      </Drawer>



    </div>
  );
}


export default SuspenseEntryList;
