import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card,Divider, Table, Button, Input, notification, Modal, Select, Avatar, Typography, TableColumnsType, Checkbox, DatePicker } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { DeleteOutlined, FilterOutlined,FileExcelOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import Invoice from "../../components/Invoice";

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { MonthPicker, RangePicker } = DatePicker;

const statusClassMap = {
  Success: 'text-success',
  Failed: 'text-danger',
  Pending: 'text-warning',
};

const virifyClassMap = {
  Verified: 'text-success',
  Failed: 'text-danger',
  Pending: 'text-warning',
};



function PaymentsList() {
  const history = useHistory();
  const location = useLocation();
  // Get current page from URL, or default to 1
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;

  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [paymentList, setPaymentList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(currentPageQuery);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: ''
  });

  // useEffect(() => {
  //   getPaymentList(currentPage);
  // }, []);

  const createStyledTitle = (text) => (
    <span style={{ fontSize: '15px', color: '#0056b3' }}>{text}</span>
  );
  
  // Table columns definition
  const columns = [
  
    {
      title: createStyledTitle("#"),
      dataIndex: "key",
      key: "key",
      fixed: 'left',
      width: 50,
    },
    {
      title: createStyledTitle("Name"),
      dataIndex: "name",
      key: "name",
      fixed: 'left',
      // sorter: true,
      width: 150,
    },
    {
      title: createStyledTitle("Receipt no."),
      dataIndex: "receipt_no",
      key: "receipt_no",
      // sorter: true,
    },
    {
      title: createStyledTitle("Amount"),
      dataIndex: "amount",
      key: "amount",
      // sorter: true,
    },
    {
      title: createStyledTitle("Tnx (UTR/ID)"),
      dataIndex: "utr_number",
      key: "utr_number",
      // sorter: true,
    },
    {
      title: createStyledTitle("Tnx Date"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      // sorter: true,
    },
  
    {
      title: createStyledTitle("Start Month"),
      dataIndex: "start_month",
      key: "start_month",
      // sorter: true,
  
    },
    {
      title: createStyledTitle("End Month"),
      dataIndex: "end_month",
      key: "end_month",
      // sorter: true,
    },
  
  
    {
      title: createStyledTitle("Duration"),
      dataIndex: "duration_type",
      key: "duration_type",
      // sorter: true,
    },
    {
      title: createStyledTitle("Monthly Share"),
      dataIndex: "announced_amount",
      key: "announced_amount",
      // sorter: true,
    },
  
    {
      title: createStyledTitle("Account"),
      dataIndex: "bank_account",
      key: "bank_account",
      // sorter: true,
    },
    {
      title: createStyledTitle("Special Occassion"),
      dataIndex: "special_occassion_category",
      key: "special_occassion_category",
      // sorter: true,
    },
  
   
    {
      title: createStyledTitle("Pay Entry Date"),
      dataIndex: "payment_entry_date",
      key: "payment_entry_date",
    },
    
    {
      title: createStyledTitle("Is Check Payment"),
      key: "is_payment_check",
      dataIndex: "is_payment_check",
      fixed: 'right',
      width: 150,  // Adjust width based on content
      render: (text, record) => (
        <div className="button-container">
          {(record.is_payment_check === 'unchecked' || record.is_payment_check === '' || record.is_payment_check === null) && (
            <Button
              type="primary"
              style={{ backgroundColor: 'rgb(255 22 56)', color: '#fff', borderColor: '#faad14' }}
              danger={record.is_payment_check === 'failed'}
              onClick={() => handleCheckPayment(record.payment_id, 'checked')}
            >
              Unchecked
            </Button>
          )}
          {record.is_payment_check === 'checked' && (
            <Button
              type="primary"
              style={{ backgroundColor: '#62e962', color: '#fff', borderColor: '#62e962' }}
              onClick={() => handleCheckPayment(record.payment_id, 'unchecked')}
            >
              Checked
            </Button>
          )}
        </div>
      ),
    },
    
  
   
    {
      title: createStyledTitle("Payment Status"),
      key: "payment_virify",
      dataIndex: "payment_virify",
      fixed: 'right',
      width: 50,
    },
    // {
    //   title: "Send Reciept",
    //   key: "reciept_status",
    //   dataIndex: "reciept_status",
    //   fixed: 'right',
    //   width: 50,
    // },
  
    {
      title: createStyledTitle("Action"),
      key: "action",
      dataIndex: "action",
      fixed: 'right',
      width: 100,
    }
  ];


  useEffect(() => {
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit
  
    // Call getPaymentList with restored page and limit
    getPaymentList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);
  
  

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRighft'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getPaymentList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {

     

      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/payments/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setPaymentList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/payments/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getPaymentList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Payment deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);
    
    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);
  
    // Fetch the updated payment list
    getPaymentList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleSearch = value => {
    setSearch(value);
    getPaymentList(currentPage, limit, sortField, sortOrder, value, filters);
  };

  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getPaymentList(1, value);  // Assuming your API call accepts page and limit
  };

  const debouncedgetPaymentList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {

    getPaymentList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);



  useEffect(() => {
    const filterKeys = Object.keys(filters);



    // Check if any filter other than 'createdBy' has a length of 3 or more
    const anyFilterHasMinLength = filterKeys.some(
      key =>
        (key !== 'createdBy' && key !== 'receipt_no' && filters[key]?.length >= 3) ||
        (key === 'receipt_no' && filters[key]?.length >= 1) ||
        (key === 'bank_account' && filters[key]?.length >= 1) ||
        (key === 'month_range' && filters[key]?.length >= 0) ||
        (key === 'txn_date_range' && filters[key]?.length >= 0) ||
        (key === 'entry_date_range' && filters[key]?.length >= 0)
    );

    // Check if all filters are either empty or specifically 'createdBy'
    const allFiltersEmptyOrCreatedBy = filterKeys.every(
      key => key === 'createdBy' || !filters[key]
    );

    if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {
      debouncedgetPaymentList(currentPage, limit, sortField, sortOrder, search, filters);
    }
  }, [filters, currentPage, limit, sortField, sortOrder, search]);

  const handleFilterChange = (field, value) => {
    console.log('........', value)
    setFilters({
      ...filters,
      [field]: value
    });
  };



  const [showPaymentFilter, setshowPaymentFilter] = useState(true);
  const [showPermanentAddress, setShowPermanentAddress] = useState(false);
  const [showTemporaryAddress, setShowTemporaryAddress] = useState(false);

  const handleUserFilterCheckboxChange = (e) => {
    setshowPaymentFilter(e.target.checked);
    if (staffList?.length === 0) {
      getStaffList();
    }
  };

  const handlePermanentAddressCheckboxChange = (e) => {
    setShowPermanentAddress(e.target.checked);
  };

  const handleTemporaryAddressCheckboxChange = (e) => {
    setShowTemporaryAddress(e.target.checked);
  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleVerifyPayment = async (paymentId) => {
    Modal.confirm({
      title: 'Are you sure you want to verify this payment?',
      onOk: async () => {
        try {
          const response = await axios.patch(`${baseUrl}/payments/paymentStatusVerify/${paymentId}`, { payment_virify: 'verified' }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
          });

          if (response.status === 200) {
            getPaymentList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
            notification.success({
              message: 'Success',
              description: 'Payment verified successfully',
              placement: 'topRight',
              duration: 1.5
            });
          }
        } catch (error) {
          notification.info({
            message: 'Info',
            description: "Failed to verify payment",
            placement: 'topRight'
          });

        }
      },
    });
  }

  const handleCheckPayment = async (paymentId,check_type) => {

    
   
        try {
          const response = await axios.patch(`${baseUrl}/payments/paymentStatusVerify/${paymentId}`, { check_type: check_type }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
          });

          if (response.status === 200) {
            getPaymentList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
            notification.success({
              message: 'Success',
              description: 'Payment update successfully',
              placement: 'topRight',
              duration: 2.0
            });
          }
        } catch (error) {
          notification.info({
            message: 'Info',
            description: "Failed to checked payment",
            placement: 'topRight'
          });

        }
      
     
  }

  const handleEdit = (id) => {
    // Navigate to another route with props
    history.push({
      pathname: `/payments/update/${id}`, // The target route
      state: { page: currentPage, limit: limit } // Props or state to pass
    });
  };
  const handleView = (id) => {
    history.push({
      pathname: `/payments/view/${id}`,
      state: { page: currentPage, limit: limit } // Pass both page and limit
    });
  };
  


  const exportToExcel = async () => {
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/payments/list?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1, 
        "Phone no": user.phone_no, 
        "Name": user.name,
        "Relation": user.relation,
        "Father Name": user.father_name,
        "Village": user.per_village,
        "District ": user.per_district, 
        "Receipt no": user.receipt_no,
        "Amount": user.amount,
        "Tnx (UTR/ID)": user.utr_number,
        "Tnx Date": user.transaction_date ? moment(user.transaction_date).format('DD-MM-YYYY') : '-', 
        "Start Month": user.start_month ? moment(user.start_month).format('MM-YYYY') : '-',
        "End Month": user.end_month ? moment(user.end_month).format('MM-YYYY') : '-',
        "Duration": user.duration_type,        
        "Monthly Share": user.announced_amount, 
        "Account": user.bank_account,
        "Special Occassion": user.special_occassion_category,
         "Pay Entry Date": user.payment_entry_date ? moment(user.payment_entry_date).format('DD-MM-YYYY') : '-',  
        "Payment Status": user.payment_virify,   
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Payment History");
      XLSX.writeFile(workbook, "Payment-History.xlsx");
    } else {
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };

  const rowClassName = (record) => {
    return record.is_payment_check === 'checked' ? 'checked-payment-row' : 'checked-row';
  };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Payment List"

            extra={
              <div style={{ display: 'flex', gap: '10px' }}>
                {hasPermission('createPermission', 'Add Payment') && (
                  <Link
                    className="custom-btn"
                    to="/payments/add_user_payment"
                    style={{
                      display: 'inline-block',
                      padding: '10px 15px',
                      fontSize: '14px',
                      lineHeight: '22px',
                      borderRadius: '5px',
                      background: '#1890ff',
                      color: '#fff',
                      textAlign: 'center',
                    }}
                  >
                    Add User
                  </Link>
                )}
                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Excel Download
                </Button>
              </div>
            }

           >
            <div className="table-responsive">
              <Table
                columns={columns}
                rowClassName={rowClassName}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>

                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Payment Filter</Title></Checkbox>
                      </Col>
                      {showPaymentFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Phone" value={filters.phone} onChange={e => handleFilterChange('phone', e.target.value)} /></Col>
                          <Col span={3}>
                            <Input placeholder="Receipt numbers" value={filters.receipt_no} onChange={(e) => handleFilterChange('receipt_no', e.target.value)} />
                          </Col>

                          <Col span={4}> <Input placeholder="Name" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} /></Col>
                          <Col span={4}><Input placeholder="Email" value={filters.email} onChange={e => handleFilterChange('email', e.target.value)} /></Col>

                          <Col span={4} ><Select style={{ width: '100%' }} placeholder="Account" value={filters.bank_account} onChange={value => handleFilterChange('bank_account', value)}>
                            <Option value="">Select</Option>
                            <Option value="1">ABRSVS(SHIKSHA NIDHI)-9078(SBI)</Option>
                            <Option value="2">ABRSVS(M_GAUSHALA)-4937(SBI)</Option>
                            <Option value="3">ABRSVS(ENVIRONMENT)-9598(AXIX)</Option>
                            <Option value="4">SKSS_BADLI(80G)</Option>
                            <Option value="5">SSS(80G)</Option>
                            <Option value="6">ANY OTHER</Option>
                          </Select>
                          </Col>

                          <Col span={4} ><Select style={{ width: '100%' }} placeholder="Special Occassion" value={filters.special_occassion_category} onChange={value => handleFilterChange('special_occassion_category', value)}>
                            <Option value="">Select</Option>
                            <Option value="Birthday">Birthday</Option>
                            <Option value="Anngegment">Anngegment</Option>
                            <Option value="Marriage">Marriage</Option>
                            <Option value="Anniversary">Anniversary</Option>
                            <Option value="Bahan-Beti-Sammelan">Bahan-Beti Sammelan</Option>
                            <Option value="Any-Other">Any Other</Option>
                          </Select>
                          </Col>

                          <Col span={4}><Input placeholder="Amount" value={filters.amount} onChange={e => handleFilterChange('amount', e.target.value)} /></Col>

                          <Col span={4}><Input placeholder="Tnx Detail (UTR/ID)" value={filters.utr_number} onChange={e => handleFilterChange('utr_number', e.target.value)} /></Col>

                          <Col span={4}> <DatePicker className="filter_dob" format="DD-MM-YYYY" value={filters.transaction_date ? moment(filters.transaction_date, 'DD-MM-YYYY') : null} onChange={(date, dateString) => handleFilterChange('transaction_date', dateString)} placeholder="Tnx Date" />  </Col>

                          <Col span={4} ><Select style={{ width: '100%' }} placeholder="Mode of Payment" value={filters.pyament_mode_type} onChange={value => handleFilterChange('pyament_mode_type', value)}>
                            <Option value="UPI">UPI</Option>
                            <Option value="Cash">Cash</Option>
                            <Option value="Cheque">Cheque</Option>
                            <Option value="RTGS-NEFT">RTGS/NEFT</Option>
                            <Option value="Website-Rozapay">Website-Rozapay</Option>
                            <Option value="Application-Rozapay">Application-Rozapay</Option>
                          </Select>
                          </Col>

                          <Col span={4}> <DatePicker className="filter_dob" format="DD-MM-YYYY" value={filters.payment_entry_date ? moment(filters.payment_entry_date, 'DD-MM-YYYY') : null} onChange={(date, dateString) => handleFilterChange('payment_entry_date', dateString)} placeholder="Pay Entry Date" />  </Col>



                          <Col span={6}>
                            <div className="amount-range-input">
                              <RangePicker
                                className="filter_dob"
                                picker="month"
                                format="MM-YYYY"
                                value={
                                  Array.isArray(filters.month_range) && filters.month_range.length === 2
                                    ? [moment(filters.month_range[0], 'MM-YYYY'), moment(filters.month_range[1], 'MM-YYYY')]
                                    : []
                                }
                                onChange={(dates, dateStrings) => {
                                  const formattedRange = dates ? dates.map(date => date.format('MM-YYYY')) : [];
                                  handleFilterChange('month_range', formattedRange);
                                }}
                                placeholder={['Start Month', 'End Month']}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </Col>


                          {/* <Col span={4} ><Select style={{ width: '100%' }} placeholder="Pay Verified Status" value={filters.payment_virify} onChange={value => handleFilterChange('payment_virify', value)}>
                            <Option value="verified">Verified</Option>
                            <Option value="failed">Failed</Option>
                            <Option value="pending">Pending</Option>
                          </Select>
                          </Col> */}



                          <Col span={8}>
                            <div className="amount-range-input">
                              <Input
                                placeholder="Min Amount"
                                value={filters.amountMin}
                                onChange={(e) => handleFilterChange('amountMin', e.target.value)}
                              />
                              <span className="range-separator">-</span>
                              <Input
                                placeholder="Max Amount"
                                value={filters.amountMax}
                                onChange={(e) => handleFilterChange('amountMax', e.target.value)}
                              />
                            </div>
                          </Col>

                          <Col span={8}>
                            <div className="amount-range-input">
                              <Input
                                placeholder="Min Monthly Share"
                                value={filters.min_monthly_amount}
                                onChange={(e) => handleFilterChange('min_monthly_amount', e.target.value)}
                              />
                              <span className="range-separator">-</span>

                              <Input
                                placeholder="Max Monthly Share"
                                value={filters.max_monthly_amount}
                                onChange={(e) => handleFilterChange('max_monthly_amount', e.target.value)}
                              />

                            </div>
                          </Col>

                          <Col span={6}>
                            <div className="amount-range-input">
                              <RangePicker
                                className="filter_dob"
                                format="DD-MM-YYYY"
                                value={
                                  Array.isArray(filters.txn_date_range) && filters.txn_date_range.length === 2
                                    ? [moment(filters.txn_date_range[0], 'DD-MM-YYYY'), moment(filters.txn_date_range[1], 'DD-MM-YYYY')]
                                    : []
                                }
                                onChange={(dates, dateStrings) => {
                                  const formattedRange = dates ? dates.map(date => date.format('DD-MM-YYYY')) : [];
                                  handleFilterChange('txn_date_range', formattedRange);
                                }}
                                placeholder={['Start Txn Date', 'End Txn Date']}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </Col>

                          <Col span={6}>
                            <div className="amount-range-input">
                              <RangePicker
                                className="filter_dob"

                                format="DD-MM-YYYY"
                                value={
                                  Array.isArray(filters.entry_date_range) && filters.entry_date_range.length === 2
                                    ? [moment(filters.entry_date_range[0], 'DD-MM-YYYY'), moment(filters.entry_date_range[1], 'DD-MM-YYYY')]
                                    : []
                                }
                                onChange={(dates, dateStrings) => {
                                  const formattedRange = dates ? dates.map(date => date.format('DD-MM-YYYY')) : [];
                                  handleFilterChange('entry_date_range', formattedRange);
                                }}
                                placeholder={['Start Entry Date', 'End Entry Date']}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </Col>

                        


                        </>
                      )}
 

                      <Col xs={24}>
                        <span style={{ marginRight: 20 }}>Records per page:</span>
                        <Select value={limit} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>

                    </Row>




                  </div>

                )}


                dataSource={paymentList.map((payment, index) => ({
                  key: (limit * (currentPage - 1)) + index + 1,
                  payment_id: payment.payment_id,  // Make sure this is part of the dataSource
                  is_payment_check: payment.is_payment_check,  // Add all other fields as necessary
                  name: (
                    <>
                      <Avatar.Group>
                        <div className="avatar-info">
                          <Title level={5}>
                            <Link to={`/users/view/${payment.user_id}`}  >
                              {payment.name}
                            </Link>
                          </Title>
                          <p>{payment.phone_no}</p>
                        </div>
                      </Avatar.Group>{" "}
                    </>
                  ),
                  receipt_no: (
                    <>
                      <div className="author-info">
                        <Title level={5}>
                          {payment?.receipt_no ? payment?.receipt_no : '-'}
                        </Title>

                      </div>
                    </>
                  ),
                  amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>Rs.{payment.amount}  </Title>

                      </div>
                    </>
                  ),
                  utr_number: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{payment.utr_number}</Title>
                      </div>
                    </>
                  ),
                  transaction_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{payment.transaction_date ? moment(payment.transaction_date).format('DD MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                  start_month: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{payment.start_month ? moment(payment.start_month).format('MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                  end_month: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{payment.end_month ? moment(payment.end_month).format('MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),

                  duration_type: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {payment.duration_type}</Title>
                      </div>
                    </>
                  ),
                  announced_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}> Rs.{payment.announced_amount}</Title>
                      </div>
                    </>
                  ),

                  bank_account: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {payment.bank_account}</Title>
                      </div>
                    </>
                  ),
                  special_occassion_category: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {payment.special_occassion_category}</Title>
                      </div>
                    </>
                  ),
                  payment_entry_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{payment.payment_entry_date ? moment(payment.payment_entry_date).format('DD MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                  is_payment_check : payment.is_payment_check,
                  
             
                  payment_virify: (
                    <div className="button-container">
                      {(payment.payment_virify === 'pending' || payment.payment_virify === 'failed') && (
                        <Button
                          type="primary"
                          style={{ backgroundColor: '#faad14', color: '#fff', borderColor: '#faad14' }}
                          danger={payment.payment_virify === 'failed'}
                          onClick={() => handleVerifyPayment(payment.payment_id)}
                        >
                          {payment.payment_virify === 'pending' ? 'Pending' : 'Failed '}
                        </Button>
                      )}
                      {payment.payment_virify === 'verified' && (
                        <Button type="primary" style={{ backgroundColor: 'green', color: '#fff', borderColor: 'green' }} disabled>
                          Verified
                        </Button>
                      )}
                    </div>
                  ),
                  // reciept_status: (
                  //   <div className="button-container">
                  //     {(payment.reciept_status === 0 || payment.reciept_status === null) && (
                  //       <Invoice userData="" paymentId={payment.payment_id} btnShow={['whatsapp']} />
                  //     )}
                  //     {payment.reciept_status === 1 && (
                  //        <Invoice userData="" paymentId={payment.payment_id} btnShow={['whatsapp']} />
                  //     )}
                  //   </div>
                  // ),

                  
                
                  action: (
                    <div className="button-container">
                      {hasPermission('editPermission', 'Edit Payment') && (
                        <button onClick={() => handleEdit(payment.payment_id)} className="update-btn">
                          <EditOutlined />
                        </button>
                      )}
                      {hasPermission('readPermission', 'Read Payment') && (
                        <Link onClick={() => handleView(payment.payment_id)} className="update-btn">
                          <EyeOutlined />
                        </Link>
                      )}
                      {hasPermission('deletePermission', 'Delete Payment') && (
                        <button onClick={() => showDeleteConfirm(payment.payment_id)} className="delete-btn">
                          <DeleteOutlined />
                        </button>
                      )}
                       

                         


                    </div>
                  )
                }))}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

    </div>
  );
}


export default PaymentsList;
