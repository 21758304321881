// AddUserDetailsModel.js
import { useState, useEffect, useRef } from "react";
import { baseUrl } from "../../config";
import moment from 'moment';
import axios from "axios";
import { Row, Col, Card, Button, Image, Space, Avatar, Upload, message, notification, InputNumber, Typography, Form, Input, Select, Checkbox, DatePicker, Collapse, Tabs, Descriptions, Divider, AutoComplete, Switch } from "antd";
import '../../assets/styles/custom_style.css';
import {
  UploadOutlined, UserOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, PhoneOutlined, MailOutlined, CalendarOutlined, HomeOutlined
} from "@ant-design/icons";
import _ from 'lodash';
import PdfImage from "../../assets/images/document.png";
import OccupationDetails from "./OccupationDetails";
import FamilyDetails from "./FamilyDetails";
import SocialStatus from "./SocialStatus";
// import AvatarEditor from 'react-avatar-edit';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

const AddUserDetailsModel = ({ userData = {}, userBasicDetail, primaryId = '', onIdChange }) => {

  const [form] = Form.useForm();
  const cropperRef = useRef(null);
  const [userCheckId, setUserCheckId] = useState(userData?.userid ? userData?.userid : null);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);  // state to store the selected file
  const [bussinessFile, setBussinessFile] = useState(null);  // state to store the selected file
  const [userId, setUserId] = useState(null);

  const [imageURL, setImageURL] = useState(false);
  const [bussinessURL, setBussinessURL] = useState(false);
  const [bussinessImageUrl, setBussinessImageUrl] = useState(false);
  const [, setLoading] = useState(false);

  const [showPermanentAddress, setShowPermanentAddress] = useState(false);
  const [showTemporaryAddress, setShowTemporaryAddress] = useState(false);
  const [showSocialLink, setShowSocialLink] = useState(false);
  const [showUloadFile, setshowUloadFile] = useState(false);
  const [multiDocument, setMultiDocument] = useState([])
  const [fileList, setFileList] = useState([]);
  const [sameAsPermanent, setSameAsPermanent] = useState(false);

  const [editorOpen, setEditorOpen] = useState(false);
  const [preview, setPreview] = useState(null);

  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [dobDate, setDobDate] = useState();
  const [permanentAddress, setPermanentAddress] = useState({
    per_address_en: "",
    per_village_en: "",
    per_post_en: "",
    per_tahsil_en: "",
    per_pin_code_en: "",
    per_district_en: "",
    per_state_en: "",
    per_country_en: "",
    per_address_hn: "",
    per_village_hn: "",
    per_post_hn: "",
    per_tahsil_hn: "",
    per_pin_code_hn: "",
    per_district_hn: "",
    per_state_hn: "",
    per_country_hn: ""
  });

  const [temporaryAddress, setTemporaryAddress] = useState({
    tem_address_en: "",
    tem_street_en: "",
    tem_city_en: "",
    tem_pin_code_en: "",
    tem_district_en: "",
    tem_state_en: "",
    tem_country_en: "",
    tem_address_hn: "",
    tem_street_hn: "",
    tem_city_hn: "",
    tem_pin_code_hn: "",
    tem_district_hn: "",
    tem_state_hn: "",
    tem_country_hn: ""
  });
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    facebook_url: "", // Add default values for social media links
    instagram_url: "",
    twitter_url: ""
  });

  useEffect(() => {
    console.log("jjjjj", userData?.userid);
    setUserCheckId(userData?.userid ? userData?.userid : null);
  }, [userData?.userid])

  const handleButtonChildrenChange = (id) => {
    if (primaryId) {
      onIdChange(id);
    }
  };

  const handlePermanentAddressCheckboxChange = (e) => {
    setShowPermanentAddress(e.target.checked);
  };

  const handleTemporaryAddressCheckboxChange = (e) => {
    setShowTemporaryAddress(e.target.checked);
  };
  const handleSocialLinkCheckboxChange = (e) => {
    setShowSocialLink(e.target.checked);
  };

  const handleUploadFileCheckboxChange = (e) => {
    setshowUloadFile(e.target.checked);
  };

  // const handleImageChange = (e) => {
  //   // Handle file selection here
  //   const file = e.target.files[0];
  //   setFile(file);
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     setImageUrl(event.target.result);
  //   };
  //   reader.readAsDataURL(file);
  //   message.info(`Selected file: ${file.name}`);
  //   setEditorOpen(true);
  // };

  const handleBussinessImageChange = (e) => {
    // Handle file selection here
    const file = e.target.files[0];
    console.log("file", file);
    setBussinessFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log("event.target.result", event.target.result);
      setBussinessImageUrl(event.target.result);
    };
    reader.readAsDataURL(file);
    message.info(`Selected file: ${file.name}`);
  };

  // const handleButtonClick = () => {
  //   // Programmatically trigger the input file selection
  //   const fileInput = document.getElementById('fileInput');
  //   fileInput.click();
  // };

  const handleBussinessButtonClick = () => {
    // Programmatically trigger the input file selection
    const fileInput = document.getElementById('fileBussinessInput');
    fileInput.click();
  };

  useEffect(() => {

    if (userData) {
      setIsUpdateMode(true);
      setImageURL(userData.profile_img)
      setBussinessURL(userData.occ_bussiness_img)
      setPermanentAddress({
        per_address_en: userData.per_address_en,
        per_village_en: userData.per_village_en,
        per_post_en: userData.per_post_en,
        per_tahsil_en: userData.per_tahsil_en,
        per_pin_code_en: userData.per_pin_code_en,
        per_district_en: userData.per_district_en,
        per_state_en: userData.per_state_en,
        per_country_en: userData.per_country_en,
        per_address_hn: userData.per_address_hn,
        per_village_hn: userData.per_village_hn,
        per_post_hn: userData.per_post_hn,
        per_tahsil_hn: userData.per_tahsil_hn,
        per_pin_code_hn: userData.per_pin_code_hn,
        per_district_hn: userData.per_district_hn,
        per_state_hn: userData.per_state_hn,
        per_country_hn: userData.per_country_hn
      });
      setMultiDocument(userData?.uploadDocuments ? JSON.parse(userData?.uploadDocuments) : [])
      // Set default values for temporary address
      setTemporaryAddress({
        tem_address_en: userData.tem_address_en,
        tem_street_en: userData.tem_street_en,
        tem_city_en: userData.tem_city_en,
        tem_pin_code_en: userData.tem_pin_code_en,
        tem_district_en: userData.tem_district_en,
        tem_state_en: userData.tem_state_en,
        tem_country_en: userData.tem_country_en,
        tem_address_hn: userData.tem_address_hn,
        tem_street_hn: userData.tem_street_hn,
        tem_city_hn: userData.tem_city_hn,
        tem_pin_code_hn: userData.tem_pin_code_hn,
        tem_district_hn: userData.tem_district_hn,
        tem_state_hn: userData.tem_state_hn,
        tem_country_hn: userData.tem_country_hn
      });

      // Set default values for social media links
      setSocialMediaLinks({
        facebook_url: userData.facebook_url,
        instagram_url: userData.instagram_url,
        twitter_url: userData.twitter_url
      });
      const formattedUserData = {
        ...userData
      };
      setDobDate(userData.dob ? userData.dob : null) // Format the date if present)
      const userId = userData.userid; // Extract user_id directly
      setUserId(userId); // Store user_id in state
      form.setFieldsValue(formattedUserData);
    } else if (userBasicDetail) {
      setIsUpdateMode(false);
      form.setFieldsValue({
        name_eng: '',
        relation_en: '',
        father_name_en: '',
        name_hn: '',
        relation_hn: '',
        father_name_hn: '',
        email_address: '',
        // dob: '',
        alternate_phone_no: '',
        alternate_phone_no2: '',
        caste: '',
        gotra: '',
        kuldevi: '',
        kulrishi: '',
        remark: '',
        pan_number: '',
        aadhar_no: '',
        phone_no: '',
        country_code: ''
      });
      const formattedUserData = {
        ...userBasicDetail,
      };
      form.setFieldsValue(formattedUserData);
      setImageURL(null)
      setBussinessURL(null)
      setPermanentAddress({
        per_address_en: "",
        per_village_en: "",
        per_post_en: "",
        per_tahsil_en: "",
        per_pin_code_en: "",
        per_district_en: "",
        per_state_en: "",
        per_country_en: "",
        per_address_hn: "",
        per_village_hn: "",
        per_post_hn: "",
        per_tahsil_hn: "",
        per_pin_code_hn: "",
        per_district_hn: "",
        per_state_hn: "",
        per_country_hn: ""
      });

      // Set default values for temporary address
      setTemporaryAddress({
        tem_address_en: "",
        tem_street_en: "",
        tem_city_en: "",
        tem_pin_code_en: "",
        tem_district_en: "",
        tem_state_en: "",
        tem_country_en: "",
        tem_address_hn: "",
        tem_street_hn: "",
        tem_city_hn: "",
        tem_pin_code_hn: "",
        tem_district_hn: "",
        tem_state_hn: "",
        tem_country_hn: ""
      });

      // Set default values for social media links
      setSocialMediaLinks({
        facebook_url: "",
        instagram_url: "",
        twitter_url: ""
      });
    }

    setUserCheckId(userData?.user_id ? userData?.user_id : null)

  }, [userData, userBasicDetail, form]);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        formData.append(key, values[key] ? values[key] : '');
      });

      //Basic Details
      if (userBasicDetail) {
        formData.append("phone_no", userBasicDetail.phone_no ? userBasicDetail.phone_no : '');
        formData.append("country_code", userBasicDetail.country_code ? userBasicDetail.country_code : '');

      }

      if (primaryId) {
        formData.append("primaryId", primaryId);
      }

      if (dobDate) {
        const formattedDate = moment(dobDate).format('YYYY-MM-DD');
        formData.append("dob", formattedDate);
      }

      // Iterate over the keys of the permanentAddress object
      for (const key in permanentAddress) {
        // Check if the value is not empty
        if (permanentAddress[key]) {
          // Append the key-value pair to the FormData object
          formData.append(key, permanentAddress[key]);
        }
      }

      // Iterate over the keys of the temporaryAddress object
      for (const key in temporaryAddress) {
        // Check if the value is not empty
        if (temporaryAddress[key]) {
          // Append the key-value pair to the FormData object
          formData.append(key, temporaryAddress[key]);
        }
      }

      // Iterate over the keys of the socialMediaLinks object
      for (const key in socialMediaLinks) {
        // Check if the value is not empty
        if (socialMediaLinks[key]) {
          // Append the key-value pair to the FormData object
          formData.append(key, socialMediaLinks[key]);
        }
      }

      if (file) {
        formData.append("file", file);
      }

      if (bussinessFile) {
        formData.append("document", bussinessFile);
      }
      if (fileList.length > 0) {
        fileList.forEach(file => {
          const blob = new Blob([file.binary], { type: file.type });
          formData.append('multiFiles', blob, file.name);
        });
      }

      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/users/update/${userId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'User updated successfully!',
            placement: 'topRight',
            duration: 1.5
          });
          setAddUserDetailsModel(false)

        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight',
            duration: 1.5
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/users/create`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'User added successfully!',
            placement: 'topRight',
            duration: 1.5
          });
          // if (primaryId) {
          onIdChange(response.data.data.user.id);
          // }
          setUserId(response.data.data.user.id);
          setUserCheckId(response.data.data.user.id)
          setIsUpdateMode(true);
          setAddUserDetailsModel(false)
          //Show model data here 
          //setAddedUserData(values); // Save added user data
          //  setIsModalVisible(true); // Show modal
          //form.resetFields();
          // history.push('/users');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight',
            duration: 1.5
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight',
        duration: 1.5
      });
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const props = {
    name: 'file',
    multiple: true,
    beforeUpload: file => {
      // Return false so that antd's default upload behavior is prevented
      return false;
    },
    onChange: info => {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setFileList(prevState => [...prevState, { name: file.name, binary: e.target.result }]);
    };
    reader.readAsArrayBuffer(file);
    return false; // Prevent automatic upload
  };

  // Function to handle input change for permanent address fields
  const handlePermanentAddressChange = (fieldName, value, columnHindi, isHindi) => {
    // Update the state with the new value
    if (isHindi) {
      setPermanentAddress({
        ...permanentAddress,
        [fieldName]: value
      });
      debounceConvertTextAddress(value, columnHindi, 'permanent');
    } else {
      if (columnHindi) {
        setPermanentAddress({
          ...permanentAddress,
          [fieldName]: value,
          [columnHindi]: value
        });
      } else {
        setPermanentAddress({
          ...permanentAddress,
          [fieldName]: value
        });
      }
    }
  };

  // Function to handle input change for temporary  address fields
  const handleTemporaryAddressChange = (fieldName, value, columnHindi, isHindi) => {
    // Update the state with the new value
    if (isHindi) {
      setTemporaryAddress({
        ...temporaryAddress,
        [fieldName]: value
      });
      debounceConvertTextAddress(value, columnHindi, 'temporary');
    } else {
      if (columnHindi) {
        setTemporaryAddress({
          ...temporaryAddress,
          [fieldName]: value,
          [columnHindi]: value
        });
      } else {
        setTemporaryAddress({
          ...temporaryAddress,
          [fieldName]: value
        });
      }

    }
  };

  // Function to handle input change for social media fields
  const handleSocialMediaChange = (fieldName, value) => {
    // Update the state with the new value
    setSocialMediaLinks({
      ...socialMediaLinks,
      [fieldName]: value
    });
  };

  const handleSameAsPermanentChange = () => {
    if (!sameAsPermanent) {
      setTemporaryAddress({
        ...temporaryAddress,
        tem_address_en: permanentAddress.per_address_en,
        tem_street_en: permanentAddress.per_village_en,
        tem_city_en: permanentAddress.per_post_en,
        tem_pin_code_en: permanentAddress.per_pin_code_en,
        tem_district_en: permanentAddress.per_district_en,
        tem_state_en: permanentAddress.per_state_en,
        tem_country_en: permanentAddress.per_country_en, // Default country
        tem_address_hn: permanentAddress.per_address_hn,
        tem_street_hn: permanentAddress.per_village_hn,
        tem_city_hn: permanentAddress.per_post_hn,
        tem_pin_code_hn: permanentAddress.per_pin_code_hn,
        tem_district_hn: permanentAddress.per_district_hn,
        tem_state_hn: permanentAddress.per_state_hn,
        tem_country_hn: permanentAddress.per_country_hn // Default country
      });
    } else {
      if (userData) {
        setTemporaryAddress({
          tem_address_en: userData.tem_address_en,
          tem_street_en: userData.tem_street_en,
          tem_city_en: userData.tem_city_en,
          tem_pin_code_en: userData.tem_pin_code_en,
          tem_district_en: userData.tem_district_en,
          tem_state_en: userData.tem_state_en,
          tem_country_en: userData.tem_country_en,
          tem_address_hn: userData.tem_address_hn,
          tem_street_hn: userData.tem_street_hn,
          tem_city_hn: userData.tem_city_hn,
          tem_pin_code_hn: userData.tem_pin_code_hn,
          tem_district_hn: userData.tem_district_hn,
          tem_state_hn: userData.tem_state_hn,
          tem_country_hn: userData.tem_country_hn
        });
      }
    }
    setSameAsPermanent(!sameAsPermanent);
  };

  const debounceConvertText = useRef(_.debounce(async (text, column) => {
    try {
      const response = await axios.post(`${baseUrl}/translate`, { text });
      form.setFieldsValue({
        [column]: response.data.translatedText,
      });
    } catch (error) {
      console.error('Error translating text:', error);
    }
  }, 300)).current; // Adjust debounce delay as 

  const debounceConvertTextAddress = useRef(_.debounce(async (text, column, addressType) => {
    try {
      const response = await axios.post(`${baseUrl}/translate`, { text });
      if (addressType === 'permanent') {
        setPermanentAddress(prevState => ({
          ...prevState,
          [column]: response.data.translatedText,
        }));
      } else {
        setTemporaryAddress(prevState => ({
          ...prevState,
          [column]: response.data.translatedText,
        }));
      }
    } catch (error) {
      console.error('Error translating text:', error);
    }
  }, 300)).current; // Adjust debounce delay as 

  function handleInputChange(text, column) {
    const newText = text;
    debounceConvertText(newText, column);
  };



  const [optionSuggestion, setOptionSuggestion] = useState([]);

  const getPanelValue = async (searchText, columnName, tableName) => {
    if (searchText.length < 3) {
      setOptionSuggestion([]);
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
        params: { q: searchText, column: columnName, table: tableName },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        setOptionSuggestion(response.data.result);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChangeSuggestion = (value, columnName) => {
    setPermanentAddress((prevAddress) => ({
      ...prevAddress,
      [columnName]: value
    }));
  };

  const handleChangeSuggestionTemp = (value, columnName) => {
    setTemporaryAddress((prevAddress) => ({
      ...prevAddress,
      [columnName]: value
    }));
  };

  const handleUpperCaseChange = (fieldName, e) => {
    const value = e.target.value;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    form.setFieldsValue({
      [fieldName]: capitalizedValue
    });
  };

  const [selectedAddressSection, setSelectedAddressSection] = useState('permanentAddress');

  const handleAddressCheckboxChange = (formType) => {
    setSelectedAddressSection(selectedAddressSection === formType ? null : formType);
  };

  // Mapping between English and Hindi values
  const relationMapping = {
    "Son of Mr.": "पुत्र श्री",
    "Daughter of Mr.": "सुपुत्री श्री",
    "Wife of Mr.": "पत्नी श्री",
    "Adopted Son of Mr.": "गोद पुत्र श्री"
  };

  // Function to handle change
  const handleRelationChange = (value) => {
    const hindiValue = relationMapping[value];
    form.setFieldsValue({
      relation_en: value,
      relation_hn: hindiValue
    });
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setDobDate(formattedDate)
    } else {
      setDobDate(null);
    }
  };

  // const handleImageChange = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     const file = e.target.files[0];
  //     setFile(file);
  //     setImageUrl(URL.createObjectURL(e.target.files[0]));
  //     setEditorOpen(true);
  //   }
  // };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFile(file);
      setImageUrl(URL.createObjectURL(file));
      setEditorOpen(true);
    }
  };

  // const handleImageChange = (e) => {
  //   // Handle file selection here
  //   const file = e.target.files[0];
  //   setFile(file);
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     setImageUrl(event.target.result);
  //   };
  //   reader.readAsDataURL(file);
  //   message.info(`Selected file: ${file.name}`);
  //   setEditorOpen(true);
  // };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onClose = () => {
    setEditorOpen(false);
  };

  // const saveCroppedImage = () => {
  //   if (preview) {
  //     setImageUrl(preview);
  //     setFile(dataURLtoFile(preview, 'croppedImage.png'));
  //     setEditorOpen(false);
  //   }
  // };

  // const dataURLtoFile = (dataurl, filename) => {
  //   const arr = dataurl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // };

  const saveCroppedImage = () => {
    const cropper = cropperRef.current.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    const croppedImageUrl = croppedCanvas.toDataURL();
    setImageUrl(croppedImageUrl);
    setFile(dataURLtoFile(croppedImageUrl, 'croppedImage.png'));
    setEditorOpen(false);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };



  return (
    <>
      <div className="tabled">
        <Tabs defaultActiveKey="1"
          size='default'>
          <TabPane tab="Basic Details" key="1">
            {/* <p>Content for Basic Details</p> */}
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className=" tablespace"
                >
                  <Form style={{ "padding": "0px" }} form={form} onFinish={onFinish}
                    initialValues={{ status: "Active" }}
                    layout="vertical">
                    <Row gutter={[12, 12]}>

                      {/* <Col xs={24} sm={12} lg={12}>
                        <Form.Item style={{ fontWeight: '500' }} className="mb-0" label="Profile Photo" name="image" valuePropName="fileList">
                          <div>
                            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleImageChange} />
                            <div className="d-flex" style={{ justifyContent: 'start', gap: '20px' }}>
                              <Avatar
                                size={80}
                                icon={<UserOutlined />}
                                src={imageUrl ? imageUrl : baseUrl + '/' + imageURL}
                                style={{ marginBottom: 0 }}
                              />
                              <div>
                                <Button icon={<UploadOutlined />} onClick={handleButtonClick}> Upload </Button>
                              </div>
                            </div>
                          </div>
                        </Form.Item>

                        {editorOpen && (
                          <div>
                            <AvatarEditor
                              width={390}
                              height={295}
                              onClose={onClose}
                              onCrop={onCrop}
                              src={imageUrl}
                            />
                            <Button onClick={saveCroppedImage}>Save</Button>
                          </div>
                        )}
                      </Col> */}
                      <Col xs={24} sm={12} lg={12}>
                        <Form.Item style={{ fontWeight: '500' }} className="mb-0" label="Profile Photo" name="image" valuePropName="fileList">
                          <div>
                            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleImageChange} />
                            <div className="d-flex" style={{ justifyContent: 'start', gap: '20px' }}>
                              <Avatar
                                size={80}
                                icon={<UserOutlined />}
                                src={imageUrl ? imageUrl : baseUrl + '/' + imageURL}
                                style={{ marginBottom: 0 }}
                              />
                              <div>
                                <Button icon={<UploadOutlined />} onClick={handleButtonClick}> Upload </Button>
                              </div>
                            </div>
                          </div>
                        </Form.Item>

                        {editorOpen && (
                          <div>
                            <Cropper
                              src={imageUrl}
                              style={{ height: 400, width: '100%' }}
                              // Cropper.js options
                              initialAspectRatio={1}
                              guides={false}
                              ref={cropperRef}
                            />
                            <Button style={{ "marginTop": 10 }} onClick={saveCroppedImage}>Save</Button>
                          </div>
                        )}
                      </Col>

                      <Col xs={24} sm={12} lg={12}>

                      </Col>



                      <Col xs={24} sm={24} lg={12}>
                        <Row gutter={[12, 0]}>
                          <Col Col xs={24} sm={24} lg={24}>
                            <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px' }} level={5}>In English</Title>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <Item label="Name" name="name_eng" rules={[{ required: true, message: 'Please enter name' }]}>
                              <Input placeholder="User Name" onChange={(e) => handleInputChange(e.target.value, 'name_hn')} prefix={<UserOutlined />} />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <Item label="Relation" name="relation_en" >
                              <Select placeholder="Relation" onChange={handleRelationChange}>
                                <Option value="Son of Mr.">Son of Mr.</Option>
                                <Option value="Daughter of Mr.">Daughter of Mr.</Option>
                                <Option value="Wife of Mr.">Wife of Mr.</Option>
                                <Option value="Adopted Son of Mr.">Adopted Son of Mr.</Option>
                              </Select>
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <Item label="Father/Husband Name" name="father_name_en" >
                              <Input placeholder="Father/Husband Name" onChange={(e) => handleInputChange(e.target.value, 'father_name_hn')} prefix={<UserOutlined />} />
                            </Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} lg={12}>
                        <Row gutter={[12, 0]}>
                          <Col Col xs={24} sm={24} lg={24}>
                            <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px' }} level={5}>In Hindi</Title>
                          </Col>
                          <Col xs={24} sm={12} lg={8}>
                            <Item label="नाम" name="name_hn" >
                              <Input placeholder="नाम दर्ज करें।" prefix={<UserOutlined />} />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <Item label="संबंध" name="relation_hn" >
                              <Select placeholder="संबंध चुनें।">
                                <Option value="पुत्र श्री">पुत्र श्री</Option>
                                <Option value="सुपुत्री श्री">सुपुत्री श्री</Option>
                                <Option value="पत्नी श्री">पत्नी श्री</Option>
                                <Option value="गोद पुत्र श्री">गोद पुत्र श्री</Option>
                              </Select>
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <Item label="पिता/पति का नाम" name="father_name_hn" >
                              <Input placeholder="पिता/पति का नाम दर्ज करें।" prefix={<UserOutlined />} />
                            </Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} lg={24}>
                        <Row gutter={[0, 0]}>
                          <Col Col xs={24} sm={24} lg={24}>
                            <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px', borderTop: 'solid 1px #eee', paddingTop: '10px', }} level={5}>Other Details</Title>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]}>
                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Email Address" name="email_address"
                              rules={[
                                {
                                  type: 'email',
                                  message: 'The input is not a valid email address!',
                                },
                              ]} >
                              <Input placeholder="Email Address" prefix={<MailOutlined />} />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={4}>
                            <Item label="DOB">
                              <DatePicker onChange={handleDateChange} value={dobDate && moment(dobDate)} className="filter_dob" format="DD/MM/YYYY" placeholder="Select Date of Birth" prefix={<CalendarOutlined />} />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Alt Phone No1" name="alternate_phone_no" rules={[{ message: 'Please enter phone no' },
                            {
                              pattern: /^\d{10,12}$/,
                              message: 'Phone no must be between 10 and 12 digits!',
                            }
                            ]}>
                              <Input placeholder="Alt Phone No" prefix={<PhoneOutlined />}
                              />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Alt Phone No2" name="alternate_phone_no2" rules={[{ message: 'Please enter phone no' },
                            {
                              pattern: /^\d{10,12}$/,
                              message: 'Phone no must be between 10 and 12 digits!',
                            }
                            ]}>
                              <Input placeholder="Alt Phone No2" prefix={<PhoneOutlined />}
                              />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Marital status" name="maritial_status" >
                              <Select placeholder="Marital status">
                                <Option value="Married">Married</Option>
                                <Option value="UnMarried">UnMarried</Option>
                                <Option value="Widowed">Widowed</Option>
                              </Select>
                            </Item>
                          </Col>


                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Caste" name="caste" >
                              <Input placeholder="Caste Name" onChange={(e) => handleUpperCaseChange('caste', e)} />
                            </Item>
                          </Col>


                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Gotra" name="gotra" >
                              <Input placeholder="Gotra Name" onChange={(e) => handleUpperCaseChange('gotra', e)} />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Kuldevi" name="kuldevi" >
                              <Input placeholder="Kuldevi Name" onChange={(e) => handleUpperCaseChange('kuldevi', e)} />
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Kulrishi" name="kulrishi" >
                              <Input placeholder="Kulrishi name" onChange={(e) => handleUpperCaseChange('kulrishi', e)} />
                            </Item>
                          </Col>
                          <Col xs={24} sm={12} lg={4}>
                            <Item label="Status" name="status" >
                              <Select placeholder="Status">
                                <Option value="Active">Active</Option>
                                <Option value="Inactive">Inactive</Option>

                              </Select>
                            </Item>
                          </Col>

                          <Col xs={24} sm={12} lg={6}>
                            <Item label="Remark" name="remark" >
                              <Input placeholder="Remark" />
                            </Item>
                          </Col>
                          <Col xs={24} sm={12} lg={6}>
                            <Item label="PAN Card Number" name="pan_number" >
                              <Input placeholder="PAN Card Number" />
                            </Item>
                          </Col>
                          <Col xs={24} sm={12} lg={6}>
                            <Item label="Aadhar No" name="aadhar_no" >
                              <Input placeholder="Aadhar No" />
                            </Item>
                          </Col>

                        </Row>
                      </Col>

                      <Descriptions bordered column={{ xs: 1, sm: 2, lg: 4 }} className="ModalTable">
                        <Descriptions.Item label="Permanent Address">
                          <Checkbox
                            checked={selectedAddressSection === 'permanentAddress'}
                            onChange={() => handleAddressCheckboxChange('permanentAddress')}
                          ></Checkbox>
                        </Descriptions.Item>
                        <Descriptions.Item label="Temporary Address">
                          <Checkbox
                            checked={selectedAddressSection === 'temporaryAddress'}
                            onChange={() => handleAddressCheckboxChange('temporaryAddress')}
                          ></Checkbox>
                        </Descriptions.Item>
                        <Descriptions.Item label="Social Media Link">
                          <Checkbox
                            checked={selectedAddressSection === 'socialLink'}
                            onChange={() => handleAddressCheckboxChange('socialLink')}
                          ></Checkbox>
                        </Descriptions.Item>
                        <Descriptions.Item label="Upload File/document">
                          <Checkbox
                            checked={selectedAddressSection === 'uploadFile'}
                            onChange={() => handleAddressCheckboxChange('uploadFile')}
                          ></Checkbox>
                        </Descriptions.Item>
                      </Descriptions>



                      {selectedAddressSection === 'permanentAddress' && (

                        <>
                          <Col xs={24} sm={24} lg={24}>
                            <Row gutter={[0, 0]}>
                              <Col Col xs={24} sm={24} lg={24}>
                                <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px', }} level={5}>Permanent Address</Title>
                              </Col>
                            </Row>
                            <Row gutter={[12, 0]}>

                              <Col xs={24} sm={12} lg={6}>
                                <Item label="Street/Building/House No.">
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_address_en", e.target.value, "per_address_hn", true)
                                  }
                                    value={permanentAddress.per_address_en} placeholder="Enter Street/Building/House No." prefix={<HomeOutlined />} />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="Village/Town/Ward" >
                                  <AutoComplete placeholder="Village/Town/Ward"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_village_en", value, "per_village_hn", true)}
                                    value={permanentAddress.per_village_en}
                                    onChange={(value) => handleChangeSuggestion(value, "per_village_en")}
                                    onSearch={(text) => getPanelValue(text, "per_village_en", "sn_user_address")}
                                  />
                                </Item>

                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="Post"  >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_post_en", e.target.value, "per_post_hn", true)
                                  }
                                    value={permanentAddress.per_post_en} placeholder="Enter Post" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="Tahsil" >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_tahsil_en", e.target.value, "per_tahsil_hn", true)
                                  }
                                    value={permanentAddress.per_tahsil_en} placeholder="Enter Tahsil" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="Pin Code"  >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_pin_code_en", e.target.value, "per_pin_code_hn", false)
                                  }
                                    value={permanentAddress.per_pin_code_en} placeholder="Enter Pin Code" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="District"  >
                                  <AutoComplete placeholder="Enter District"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_district_en", value, "per_district_hn", true)}
                                    value={permanentAddress.per_district_en}
                                    onChange={(value) => handleChangeSuggestion(value, "per_district_en")}
                                    onSearch={(text) => getPanelValue(text, "per_district_en", "sn_user_address")}
                                  />
                                </Item>

                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="State"  >
                                  <AutoComplete placeholder="Enter State"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_state_en", value, "per_state_hn", true)}
                                    value={permanentAddress.per_state_en}
                                    onChange={(value) => handleChangeSuggestion(value, "per_state_en")}
                                    onSearch={(text) => getPanelValue(text, "per_state_en", "sn_user_address")}
                                  />


                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="Country" >
                                  <AutoComplete placeholder="Enter Country"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_country_en", value, "per_country_hn", true)}
                                    value={permanentAddress.per_country_en}
                                    onChange={(value) => handleChangeSuggestion(value, "per_country_en")}
                                    onSearch={(text) => getPanelValue(text, "per_country_en", "sn_user_address")}
                                  />

                                </Item>
                              </Col>

                            </Row>

                            {/* स्थाई पता */}

                            <Row gutter={[0, 0]}>
                              <Col Col xs={24} sm={24} lg={24}>
                                <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px', borderTop: 'solid 1px #eee', paddingTop: '10px', }} level={5}>स्थाई पता</Title>
                              </Col>
                            </Row>
                            <Row gutter={[12, 0]}>

                              <Col xs={24} sm={12} lg={6}>
                                <Item label="गली/बास/मकान नंबर."  >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_address_hn", e.target.value)
                                  }
                                    value={permanentAddress.per_address_hn} placeholder="गली/बास/मकान नंबर दर्ज करें" prefix={<HomeOutlined />} />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="गाँव/कस्बा/वार्ड" >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_village_hn", e.target.value)
                                  }
                                    value={permanentAddress.per_village_hn} placeholder="गाँव/कस्बा/वार्ड दर्ज करें" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="पोस्ट" >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_post_hn", e.target.value)
                                  }
                                    value={permanentAddress.per_post_hn} placeholder="पोस्ट दर्ज करें" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="तहसील" >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_tahsil_hn", e.target.value)
                                  }
                                    value={permanentAddress.per_tahsil_hn} placeholder="तहसील दर्ज करें" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="पिनकोड" >
                                  <Input onChange={(e) =>
                                    handlePermanentAddressChange("per_pin_code_hn", e.target.value)
                                  }
                                    value={permanentAddress.per_pin_code_hn} placeholder="पिनकोड दर्ज करें" />
                                </Item>
                              </Col>


                              <Col xs={24} sm={12} lg={4}>

                                <Item label="जिला" >
                                  <AutoComplete placeholder="जिला दर्ज करें"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_district_hn", value, "per_district_hn", false)}
                                    value={permanentAddress.per_district_hn}
                                    onChange={(value) => handleChangeSuggestion(value, "per_district_hn")}
                                    onSearch={(text) => getPanelValue(text, "per_district_hn", "sn_user_address")}
                                  />

                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="राज्य" >
                                  <AutoComplete placeholder="जिला राज्य करें"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_state_hn", value, "per_state_hn", false)}
                                    value={permanentAddress.per_state_hn}
                                    onChange={(value) => handleChangeSuggestion(value, "per_state_hn")}
                                    onSearch={(text) => getPanelValue(text, "per_state_hn", "sn_user_address")}
                                  />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="देश" >
                                  <AutoComplete placeholder="जिला देश करें"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("per_country_hn", value, "per_country_hn", false)}
                                    value={permanentAddress.per_country_hn}
                                    onChange={(value) => handleChangeSuggestion(value, "per_country_hn")}
                                    onSearch={(text) => getPanelValue(text, "per_country_hn", "sn_user_address")}
                                  />


                                </Item>
                              </Col>

                            </Row>
                          </Col>


                        </>
                      )}



                      {selectedAddressSection === 'temporaryAddress' && (
                        <>

                          <Col xs={24} sm={24} lg={24}>
                            <Row gutter={[0, 0]}>
                              <Col Col xs={24} sm={24} lg={12}>
                                <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px', }} level={5}>Temporary Address</Title>
                              </Col>
                              <Col xs={24} sm={12} lg={12} style={{ textAlign: 'right' }}>
                                <Checkbox style={{ fontSize: '12px' }} onChange={handleSameAsPermanentChange}>Same as Permanent Address</Checkbox>
                              </Col>
                            </Row>
                            <Row gutter={[12, 0]}>



                              <Col xs={24} sm={12} lg={4}>
                                <Item label="House/Flat/Building" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_address_en", e.target.value, "tem_address_hn", true)
                                  }
                                    value={temporaryAddress.tem_address_en} placeholder="House No/Flat No/Building Name" prefix={<HomeOutlined />} />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="Village/Town/Ward" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_street_en", e.target.value, "tem_street_hn", true)
                                  }
                                    value={temporaryAddress.tem_street_en} placeholder="Enter Town/City/Ward" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="City" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_city_en", e.target.value, "tem_city_hn", true)
                                  }
                                    value={temporaryAddress.tem_city_en} placeholder="Enter City" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={3}>
                                <Item label="Pin Code" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_pin_code_en", e.target.value, "tem_pin_code_hn", false)
                                  }
                                    value={temporaryAddress.tem_pin_code_en} placeholder="Enter Pin Code" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={3}>
                                <Item label="District" >
                                  <AutoComplete placeholder="District"

                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("tem_district_en", value, "tem_district_hn", true)}
                                    value={temporaryAddress.tem_district_en}
                                    onChange={(value) => handleChangeSuggestionTemp(value, "tem_district_en")}
                                    onSearch={(text) => getPanelValue(text, "tem_district_en", "sn_user_address")}
                                  />


                                </Item>
                              </Col>


                              <Col xs={24} sm={12} lg={3}>
                                <Item label="State" >
                                  <AutoComplete placeholder="State"

                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("tem_state_en", value, "tem_state_hn", true)}
                                    value={temporaryAddress.tem_state_en}
                                    onChange={(value) => handleChangeSuggestionTemp(value, "tem_state_en")}
                                    onSearch={(text) => getPanelValue(text, "tem_state_en", "sn_user_address")}
                                  />

                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={3}>
                                <Item label="Country" >

                                  <AutoComplete placeholder="Country"

                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("tem_country_en", value, "tem_country_hn", true)}
                                    value={temporaryAddress.tem_country_en}
                                    onChange={(value) => handleChangeSuggestionTemp(value, "tem_country_en")}
                                    onSearch={(text) => getPanelValue(text, "tem_country_en", "sn_user_address")}
                                  />

                                </Item>
                              </Col>

                            </Row>

                            <Row gutter={[0, 0]}>
                              <Col Col xs={24} sm={24} lg={24}>
                                <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px', borderTop: 'solid 1px #eee', paddingTop: '10px', }} level={5}>अस्थाई पता</Title>
                              </Col>
                            </Row>

                            <Row gutter={[12, 0]}>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="मकान/फ्लैट" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_address_hn", e.target.value)
                                  }
                                    value={temporaryAddress.tem_address_hn} placeholder="मकान/फ्लैट" prefix={<HomeOutlined />} />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="गाँव/कस्बा/वार्ड" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_street_hn", e.target.value)
                                  }
                                    value={temporaryAddress.tem_street_hn} placeholder="नगर/कस्बा/वार्ड दर्ज करें" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={4}>
                                <Item label="सिटी" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_city_hn", e.target.value)
                                  }
                                    value={temporaryAddress.tem_city_hn} placeholder="सिटी दर्ज करें" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={3}>
                                <Item label="पिनकोड" >
                                  <Input onChange={(e) =>
                                    handleTemporaryAddressChange("tem_pin_code_hn", e.target.value)
                                  }
                                    value={temporaryAddress.tem_pin_code_hn} placeholder="पिनकोड दर्ज करें" />
                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={3}>
                                <Item label="जिला" >

                                  <AutoComplete placeholder="जिला दर्ज करें"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("tem_district_hn", value, "tem_district_hn", false)}
                                    value={temporaryAddress.tem_district_hn}
                                    onChange={(value) => handleChangeSuggestionTemp(value, "tem_district_hn")}
                                    onSearch={(text) => getPanelValue(text, "tem_district_hn", "sn_user_address")}
                                  />


                                </Item>
                              </Col>


                              <Col xs={24} sm={12} lg={3}>
                                <Item label="राज्य" >

                                  <AutoComplete placeholder="राज्य दर्ज करें"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("tem_state_hn", value, "tem_state_hn", false)}
                                    value={temporaryAddress.tem_state_hn}
                                    onChange={(value) => handleChangeSuggestionTemp(value, "tem_state_hn")}
                                    onSearch={(text) => getPanelValue(text, "tem_state_hn", "sn_user_address")}
                                  />


                                </Item>
                              </Col>

                              <Col xs={24} sm={12} lg={3}>
                                <Item label="देश"  >

                                  <AutoComplete placeholder="देश राज्य करें"
                                    options={optionSuggestion}
                                    onSelect={(value) => handlePermanentAddressChange("tem_country_hn", value, "tem_country_hn", false)}
                                    value={temporaryAddress.tem_country_hn}
                                    onChange={(value) => handleChangeSuggestionTemp(value, "tem_country_hn")}
                                    onSearch={(text) => getPanelValue(text, "tem_country_hn", "sn_user_address")}
                                  />

                                </Item>
                              </Col>

                            </Row>
                          </Col>

                        </>
                      )}



                      {selectedAddressSection === 'socialLink' && (
                        <>

                          <Col xs={24} sm={24} lg={24}>
                            <Row gutter={[0, 0]}>
                              <Col Col xs={24} sm={24} lg={12}>
                                <Title style={{ color: '#165a99', margin: '0 0 5px', fontSize: '14px', }} level={5}>Social Media Link</Title>
                              </Col>
                            </Row>

                            <Row gutter={[12, 0]}>
                              <Col xs={24} sm={12} lg={8}>
                                <Item label="Facebook Profile URL" >
                                  <Input onChange={(e) =>
                                    handleSocialMediaChange("facebook_url", e.target.value)
                                  }
                                    value={socialMediaLinks.facebook_url} placeholder="Facebook Profile URL" prefix={<FacebookOutlined />} />
                                </Item>
                              </Col>
                              <Col xs={24} sm={12} lg={8}>
                                <Item label="Instagram Profile URL" >
                                  <Input onChange={(e) =>
                                    handleSocialMediaChange("instagram_url", e.target.value)
                                  }
                                    value={socialMediaLinks.instagram_url} placeholder="Instagram Profile URL" prefix={<InstagramOutlined />} />
                                </Item>
                              </Col>
                              <Col xs={24} sm={12} lg={8}>
                                <Item label="Twitter Profile URL" >
                                  <Input onChange={(e) =>
                                    handleSocialMediaChange("twitter_url", e.target.value)
                                  }
                                    value={socialMediaLinks.twitter_url} placeholder="Twitter Profile URL" prefix={<TwitterOutlined />} />
                                </Item>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}

                      {selectedAddressSection === 'uploadFile' && (
                        <>
                          <Col xs={24} sm={24} lg={24}>
                            <Row gutter={[0, 0]}>
                              <Col Col xs={24} sm={24} lg={12}>
                                <Title style={{ color: '#165a99', margin: '0 0 10px', fontSize: '14px', }} level={5}>Upload File/document</Title>
                              </Col>
                            </Row>

                            <Row gutter={[12, 0]}>
                              <Col xs={24} sm={12} lg={24}>
                                <Upload

                                  beforeUpload={beforeUpload}
                                  multiple
                                  fileList={fileList.map(file => ({
                                    uid: file.name,
                                    name: file.name,
                                    status: 'done',
                                  }))}
                                  onRemove={(file) => {
                                    setFileList(prevState => prevState.filter(item => item.name !== file.name));
                                  }}
                                >
                                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                                <br />
                                <Space size={20} direction="vertical"></Space>
                                <Image.PreviewGroup
                                  style={{ marginTop: "10px" }}
                                  preview={{
                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                  }}
                                >
                                  <Space size="middle" wrap>
                                    {multiDocument.map((item, i) => {
                                      const isPdf = item.endsWith('.pdf');
                                      if (isPdf) {
                                        return (
                                          <div key={i} style={{ width: 75, height: 75, position: 'relative' }}>
                                            <a
                                              href={`${baseUrl}/${item}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10 }}
                                            >
                                              <img
                                                src={PdfImage}
                                                alt="PDF thumbnail"
                                                style={{ width: '100%', height: '100%' }}
                                              />
                                            </a>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <Image
                                            key={i}
                                            width={75}
                                            src={`${baseUrl}/${item}`}
                                            style={{ marginBottom: 8 }}
                                          />
                                        );
                                      }
                                    })}
                                  </Space>
                                </Image.PreviewGroup>

                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                    </Row>
                    <div className="user_add_form_details" >
                      <Space>
                        <Button type="primary" onClick={() => form.submit()}>Save Detail</Button>
                      </Space>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
            {/* Add your form or details here */}
          </TabPane>
          {/* {userCheckId && (
            <> */}
          <TabPane tab="Occupation/Bussiness details" key="2">
            {/* <p>Content for Occupation Details</p> */}
            <OccupationDetails user_id={userCheckId} />
            {/* Add your form or details here */}
          </TabPane>
          <TabPane tab="Family Details" key="3">
            {/* <p>Content for Family Details</p> */}
            <FamilyDetails user_id={userCheckId} />
            {/* Add your form or details here */}
          </TabPane>
          <TabPane tab="Social Status" key="4">
            {/* <p>Content for Socail Status</p> */}
            <SocialStatus user_id={userCheckId} />
            {/* Add your form or details here */}
          </TabPane>
          {/* </>
          )} */}
        </Tabs>
      </div>
    </>
  );
};

export default AddUserDetailsModel;