import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    Typography,
    notification,
    Form,
    Input,
    Select,
} from "antd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

function StaffForm() {
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [userType, setUserType] = useState(""); // State to track selected userType

    useEffect(() => {
        fetchRoleList();
        // Check if id exists to determine if it's an update mode
        if (id) {
            setIsUpdateMode(true);
            fetchRoleDetails();
        }
    }, [id]);

    const fetchRoleList = async () => {
        try {
            const response = await axios.get(`${baseUrl}/role/listAll`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                setRoleList(response.data.result);
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.log(error)
        }
    };

    const fetchRoleDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/staff/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);
                form.setFieldsValue(response.data.result); // Populate form fields with fetched data
                setUserType(response.data.result.userType); // Set the initial userType state
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {
            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/staff/update/${id}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Staff updated successfully!',
                        placement: 'topRight'
                    });
                    history.push('/staff');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                const response = await axios.post(`${baseUrl}/staff/create`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Staff added successfully!',
                        placement: 'topRight'
                    });
                    form.resetFields();
                    history.push('/staff');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const handleUserTypeChange = (value) => {
        setUserType(value);
    };

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={isUpdateMode ? "Update Staff" : "Add Staff"}
                    >
                        <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[{ required: true, message: 'Please enter first name' }]}
                                    >
                                        <Input placeholder="Enter first name" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[{ required: false, message: 'Please enter last name' }]}
                                    >
                                        <Input placeholder="Enter last name" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Phone Number"
                                        name="phoneNo"
                                        rules={[{ required: true, message: 'Please enter phone number' }]}
                                    >
                                        <Input placeholder="Enter phone number" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, message: 'Please enter email' }]}
                                    >
                                        <Input placeholder="Enter email" />
                                    </Item>
                                </Col>
                                {!isUpdateMode &&
                                    <Col xs={24} sm={12} lg={12}>
                                        <Item
                                            label="Password"
                                            name="password"
                                            rules={[{ required: true, message: 'Please enter password' }]}
                                        >
                                            <Input type="password" placeholder="Enter password" />
                                        </Item>
                                    </Col>
                                }
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Type"
                                        name="userType"
                                        rules={[{ required: true, message: 'Please select type' }]}
                                    >
                                        <Select
                                            placeholder="Please select type"
                                            onChange={handleUserTypeChange} // Handle change event
                                        >
                                            <Option value="Admin">Admin</Option>
                                            <Option value="Staff">Staff</Option>
                                        </Select>
                                    </Item>
                                </Col>
                                {userType === "Staff" &&
                                    <Col xs={24} sm={12} lg={12}>
                                        <Item
                                            label="Role"
                                            name="roleId"
                                            rules={[{ required: true, message: 'Please select role' }]}
                                        >
                                            <Select placeholder="Please select role">
                                                {roleList.map((item, i) => (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </Item>
                                    </Col>
                                }
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Status"
                                        name="status"
                                        rules={[{ required: true, message: 'Please select status' }]}
                                    >
                                        <Select placeholder="Please select status">
                                            <Option value="Active">Active</Option>
                                            <Option value="Inactive">Inactive</Option>
                                        </Select>
                                    </Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} lg={8}>
                                    <Item>
                                        <Button type="primary" htmlType="submit">
                                            {isUpdateMode ? "Update" : "Add"}
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default StaffForm;
