// src/components/Invoice.js


import React, { useState, useRef } from 'react';
import { PageHeader, Row, Col, Table, Button, Modal, Image, notification, Spin } from 'antd';
import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import { Typography } from 'antd';
import { ToWords } from 'to-words';
import mainLogo from '../assets/images/brahmdhamtirth-logo.jpeg';
import babajiLogo from '../assets/images/brahmaavtar_guruji.jpeg';
import signLogo from '../assets/images/sign_pic.png';
import axios from "axios";
import { baseUrl } from "../config";


const { Title, Text } = Typography;


const toWords = new ToWords({
    localeCode: 'hi-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: true,
        ignoreZeroCurrency: false,
        doNotAddOnly: true,
        currencyOptions: {
            // can be used to override defaults for the selected locale
            name: 'रुपया',
            plural: 'रुपये',
            symbol: '₹',
            fractionalUnit: {
                name: 'Paisa',
                plural: 'Paise',
                symbol: '',
            },
        },
    },
});



const Invoice = ({ userData, btnShow = [], displayView = 'none' ,paymentId=''}) => {

    const imgContainerRef = useRef(null);
    const [loading, setLoading] = useState(false); // Loading state
    const [receiptStatus, setReceiptStatus] = useState(userData?.reciept_status || 0);

 

    async function exportImagePdf(e, title = "certificate", type = "img") {


    
        e.preventDefault();

        if (imgContainerRef.current) {
            console.log("ref", imgContainerRef.current)

            //imgContainerRef.current.style.position = 'absolute';  // Resetting to default
            //imgContainerRef.current.style.left = '-10000px';  
            imgContainerRef.current.style.display = 'block';

            const { offsetWidth, offsetHeight } = imgContainerRef.current;

            // Ensure html2canvas completes rendering before continuing
            html2canvas(imgContainerRef.current, {
                width: offsetWidth,
                height: offsetHeight,
                scale: 2, // Example: increase scale for higher resolution
                scrollY: -window.scrollY,
            }).then((canvas) => {
                imgContainerRef.current.style.display = 'none';
                imgContainerRef.current.style.position = '';  // Resetting to default
                imgContainerRef.current.style.left = '';

                const chartImage = canvas.toDataURL("image/jpeg", 1.0); // Use JPEG format for the image
                let nameTitle = (title.replace(/\s/g, '-') || "Invoice") + "-contribution";
                if (type === "pdf") {
                    // Generate PDF
                    const pdf = new jsPDF(
                        {
                            orientation: 'landscape',
                            unit: 'mm',
                            format: 'a5'
                        }
                    );
                    pdf.addImage(chartImage, "JPEG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
                    pdf.save(nameTitle);

                }
                else if (type === "pdfprint") {
                    const pdf = new jsPDF(
                        {
                            orientation: 'landscape',
                            unit: 'mm',
                            format: 'a5'
                        }
                    );
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = pdf.internal.pageSize.getHeight();
                    pdf.addImage(chartImage, "JPEG", 0, 0, pdfWidth, pdfHeight);

                    // Convert PDF to Blob
                    const blob = pdf.output('blob');

                    // Create URL for the Blob
                    const url = URL.createObjectURL(blob);

                    // Open the PDF in a new window/tab
                    var newWindow = window.open(url, '_blank');
                    if (newWindow) {
                        newWindow.onload = function () {
                            newWindow.print();
                        };
                    }
                }
                else if (type == "whatsapp") {
                    // Modal.confirm({
                    //     title: 'Are you sure you want to send this receipt?',
                    //     onOk: async () => {
                           // setLoading(true); // Show loader
                            const pdf = new jsPDF(
                                {
                                    orientation: 'landscape',
                                    unit: 'mm',
                                    format: 'a5'
                                }
                            );
                            const pdfWidth = pdf.internal.pageSize.getWidth();
                            const pdfHeight = pdf.internal.pageSize.getHeight();
                            pdf.addImage(chartImage, "JPEG", 0, 0, pdfWidth, pdfHeight);

                            // Convert PDF to Blob
                            const blob = pdf.output('blob');

                            // Convert Blob to Base64
                            const reader = new FileReader();
                            reader.onloadend = function () {
                                const base64String = reader.result.split(',')[1]; // Get base64 string
                                sendWhatsAppMessage(base64String, nameTitle);
                            };
                            reader.readAsDataURL(blob);
                      //  },
                  //  });

                }
                else {

                    // Create a link and download the image
                    const link = document.createElement("a");
                    link.href = chartImage;
                    link.download = nameTitle + '.png';
                    link.click();
                }
            }).catch(error => {
                console.error('Error rendering image:', error);
                imgContainerRef.current.style.display = 'none';
                imgContainerRef.current.style.position = '';  // Resetting to default
                imgContainerRef.current.style.left = '';
            });
        }
    }

    const sendWhatsAppMessage = (pdfBase64, fileName) => {
        //send over whatsapp
        const dataUser = {
            phoneNumber: userData?.phone_no,
            pdfBase64: pdfBase64,
            filename: fileName,
            mimeType: 'application/pdf',
            userId: userData?.user_id,
            name: userData?.name_hn || userData?.name_eng,
            payment_id: userData?.payment_id,
        };
        console.log('dataUser--',dataUser)

        try {
            const response = axios.post(`${baseUrl}/send-message/whatsapp-message`, dataUser, {
                //const responsePost =  axios.post(`http://localhost:3000/send-message/whatsapp-message`,dataUser, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            }).then((response) => {

                console.log('response--',response)

                if (response.status === 200) {

                    notification.info({
                        message: 'Success',
                        description: "Message sent successfully.",
                        placement: 'topRight'
                    });
                    setReceiptStatus(1);

                } else {
                    notification.info({
                        message: 'Info',
                        description: response?.data?.message,
                        placement: 'topRight'
                    });
                }
            }).catch((error) => {
                console.error("API error:", error);
                notification.info({
                    message: 'Info',
                    description: error.response?.data?.message,
                    placement: 'topRight'
                });
            });


        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
        finally {
            setLoading(false); // Hide loader
        }
    }



    const formatDate = (dateString) => {
        if (dateString && dateString !== "undefined") {
            const date = new Date(dateString);

            // Get year
            const year = date.getFullYear();

            // Get month short name (e.g., Jan, Feb, Mar, etc.)
            const monthShortName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);

            // Get date
            const day = date.getDate();

            // Format the output string
            const formattedDate = `${day}-${monthShortName}-${year}`;

            return formattedDate;
        }
        else {
            return dateString;
        }
    };

    const vartmaan = () => {
        if (userData?.tem_district_hn) {
            return userData?.tem_district_hn;
        }
        else if (userData?.tem_district_en) {
            return userData?.tem_district_en;
        }
        else if (userData?.per_district_hn) {
            return userData?.per_district_hn;
        }
        else {
            return userData?.per_district_en || '';
        }
    }
    //,width:'595.28px'


    return (
        <>
            <div style={{ padding: '10px 10px', display: displayView }} ref={imgContainerRef}>
                <div style={{ border: '2px solid #b04e4f', marginTop: '20px', marginBottom: '15px' }} >
                    <div style={{ padding: '10px 10px' }}>


                        {/* Row 2 */}
                        <Row gutter={[16, 16]} style={{ marginTop: '0px' }}>
                            <Col span={4} style={{ textAlign: 'center' }}>
                                <img height={120} alt='' src={mainLogo} />

                                <Text className='d-block InvoiceLeft'>ब्रह्मर्षि <br /> ब्रह्माचार्य ब्रह्मा <br /> सावित्री सिद्ध <br /> पीठाधीश्वर श्री <br />तुलछारामजी महाराज <br /> अध्यक्ष</Text>
                            </Col>

                            <Col span={16} style={{ textAlign: 'center' }} className='invoiceTopHead'>
                                <Row className='inVoiceheader'>
                                    <Col span={8} style={{ textAlign: 'center' }}>
                                        <Title level={5} style={{ margin: 0 }}>|| ॐ ब्रह्मणे नमः ||</Title>
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'center' }}>
                                        <Title level={5} style={{ margin: 0 }}>|| श्री गणेशाय नम: ||</Title>
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'center' }}>
                                        <Title level={5} style={{ margin: 0 }}>|| श्री खेतेश्वराय नमः ||</Title>
                                    </Col>
                                </Row>
                                <Title level={5}>श्री ब्रह्मर्षि ब्रह्माचार्य श्री श्री तुलछारामजी महाराज की सत्प्रेरणा से</Title>
                                <Title level={2} style={{ marginTop: 0 }}>अखिल भारतीय राजपुरोहित समाज विकास संस्थान आसोतरा</Title>
                                <Title style={{ marginTop: 0, marginBottom: "10px", }}>समाज शिक्षा निधि समर्पण योजना</Title>
                                <Title level={3} style={{ marginTop: 0 }}>प्रधान कार्यालय:- श्री खेतेश्वर ब्रह्मधाम तीर्थ आसोतरा, जिला बाड़मेर (राज.)</Title>
                                <Title level={4} style={{ margin: 0 }}>रजि नं. 57/1997-98/22-12-1997 PAN No. AAATA2501N</Title>
                            </Col>


                            <Col span={4} style={{ textAlign: 'center' }}>
                                <img style={{ borderRadius: '4px' }} height={120} alt='' src={babajiLogo} />

                                <Text className='d-block InvoiceLeft'>ब्रह्मर्षि श्री <br /> खेतारामजी <br /> महाराज संस्थापक  </Text>
                                <Text className='d-block InvoiceLeft'>ब्रह्मचारी वेदान्ताचार्य  <br /> डॉ. ध्यानारामजी  <br /> (कार्यकारी अध्यक्ष)  </Text>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                            <Col span={12}>
                                <Title level={5}><strong>रसीद नं :</strong> <span style={{ paddingLeft: '5px' }}>{userData?.receipt_no}</span></Title>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Title level={5}><strong>दिनांक :</strong> <span style={{ paddingLeft: '5px' }}>{userData?.pyament_mode_type == "Cash" ? formatDate(userData?.payment_entry_date) : formatDate(userData?.transaction_date)}</span></Title>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} style={{ marginTop: '10px' }} className='InvoiceForm'>
                            <Col span={8}>
                                <Title level={5}>श्रीमान् : <span>{userData?.name_hn ? userData?.name_hn : userData?.name_eng}</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>{userData?.relation_hn} : <span>{userData?.father_name_hn ? userData?.father_name_hn : userData?.father_name_en}</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>गांव : <span>{userData?.per_village_hn ? userData?.per_village_hn : userData?.per_village_en}</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>तहसील : <span>{userData?.per_tahsil_hn ? userData?.per_tahsil_hn : userData?.per_tahsil_en}</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>जिला : <span>{userData?.per_district_hn ? userData?.per_district_hn : userData?.per_district_en}</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>राज्य : <span>{userData?.per_state_hn ? userData?.per_state_hn : userData?.per_state_en}</span></Title>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>वर्तमान : <span>{userData?.tem_district_hn ? userData?.tem_district_hn : userData?.tem_district_en}</span></Title>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>रुपये : <span>{userData?.amount ? userData?.amount + '/-' : ''}</span></Title>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>अक्षरे रुपये : <span>{userData?.amount ? toWords.convert(userData?.amount) : ''} मात्र</span></Title>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>रोकड़/चैक/UPI/ RTGS/ : <span>{userData?.pyament_mode_type == "UPI" ? userData?.pyament_mode_type + '-' + userData?.utr_number : userData?.pyament_mode_type}</span></Title>
                            </Col>
                            <Col span={24}>
                                <Title level={5}>से समाज शिक्षा निधि समर्पण योजना के तहत सधन्यवाद प्राप्त हुए।</Title>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} style={{ marginTop: '0px', marginBottom: '10px', alignItems: 'end' }} className='Invoicelast'>
                            <Col span={8}>
                                <div className='rupeesBox'>
                                    <i>₹</i><span style={{ marginLeft: '10px', fontSize: '25px', fontWeight: 500, fontStyle: 'italic' }}>{userData?.amount}/-</span>
                                </div>
                            </Col>
                            <Col span={8} className='text-center'>

                                <Title level={4} className='text-center'>हस्ताक्षर देनेवाला </Title>
                                <div className='InvoiceForm'>
                                    <Title level={5}><i>संपर्क सूत्र:</i> <span>{userData?.phone_no || '      '}</span></Title>
                                </div>
                            </Col>
                            <Col span={8} className='text-center'>
                                <img height={50} alt='' src={signLogo} />
                                <Title level={4} className='text-center mt-2'>हस्ताक्षर प्राप्तकर्ता </Title>
                                <div className='InvoiceForm'>

                                    <Title level={5}><i>संपर्क सूत्र:</i> <span>7023131008, 9030301008</span></Title>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='inVoiceFooter'>
                        <Row>
                            <Col span={8}>
                                <Title level={5}>Website : <span>www.brahmdhamtirth.org</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>E-mail : <span>asotra@brahmdhamtirth.org</span></Title>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>Phone : <span>7023131008, 9030301008</span></Title>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            

                {btnShow.includes('img_download') &&

                    <Button style={{ marginRight: '5px' }} onClick={(e) => exportImagePdf(e, userData?.name_eng)}>Image Download</Button>
                }
                {btnShow.includes('pdf_download') &&
                    <Button style={{ marginRight: '5px' }} onClick={(e) => exportImagePdf(e, userData?.name_eng, 'pdf')}>PDF Download</Button>
                }
                {btnShow.includes('pdf_print') &&
                    <Button style={{ marginRight: '5px' }} onClick={(e) => exportImagePdf(e, userData?.name_eng, 'pdfprint')}>PDF Print</Button>
                }
                {btnShow.includes('whatsapp') &&
                    <Button title="Send" color="blue" onClick={(e) => exportImagePdf(e, userData?.name_eng, 'whatsapp')}>
                          {receiptStatus === 1 ? 'Re-send Receipt' : 'Send Receipt'}

                    </Button>
                }


           

            <style>
                {`
              .ant-spin-container {   float: left; }
               }
               `}
            </style>

        </>
    );


};

export default Invoice;