import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Button, Divider, Typography, notification, Drawer, Space, Form, Input, InputNumber, Select, Upload, Checkbox, DatePicker, Modal, message, Avatar, Flex } from "antd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { PlusOutlined, UploadOutlined, UserOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, PhoneOutlined, MailOutlined, CalendarOutlined, HomeOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import '../../assets/styles/custom_style.css';
import UserModalContent from './UserModalContent';
import AddUserDetailsModel from './AddUserDetailsModel';
import moment from 'moment';
import _ from 'lodash';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

function PaymentsEditForm() {
    const location = useLocation();
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [file, setFile] = useState(null);  // state to store the selected file
    const [userBasicDetail, setuserBasicDetail] = useState({});  // state to store the selected file
    const [userId, setUserId] = useState(null);
    const [transactionDate, setTransactionDate] = useState();
    const [selectedBankAccount, setSelectedBankAccount] = useState('');
    const [showSpecialOccassionCategory, setSpecialOccassionCategory] = useState(false);
    const [showCategoryPay, setCategoryPay] = useState(true);
    const [paidMonths, setPaidMonths] = useState([]);
    const [showMonthCoverd, setshowMonthCoverd] = useState(true);
    const { page } = location.state || {};
    const [ereciept_date, setEreciept_date] = useState();
    const [showEReceiptFields, setShowEReceiptFields] = useState(false);

    useEffect(() => {
        // Check if id exists to determine if it's an update mode
        if (id) {
            setIsUpdateMode(true);
            fetchUserDetails();
        }

    }, [id]);


    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/payments/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);


                const userData = response.data.result;
                const months = await getPaidMonths(userData.user_id);
                setUserId(userData.user_id)
                setTransactionDate(userData.transaction_date ? userData.transaction_date : null);
                setEreciept_date(userData.e_reciept_date ? userData.e_reciept_date : null);

                // setTxnDate(response.data.result.txn_date ? response.data.result.txn_date : null) // Format the date if present)
                const eReceiptPaymentModes = ['Cash', 'Website-Rozapay', 'Application-Rozapay'];
                if (eReceiptPaymentModes.includes(userData?.pyament_mode_type)) {
                    setShowEReceiptFields(true);
                  }

                userData.payment_entry_date = userData.payment_entry_date ? moment(userData.payment_entry_date) : null;
                if (userData.start_month) {
                    userData.month_range = [
                        moment(userData.start_month),
                        moment(userData.end_month)
                    ]
                }
                userData.start_month = userData.start_month ? moment(userData.start_month) : null;
                userData.end_month = userData.end_month ? moment(userData.end_month) : null;



                if (userData.profile_img) {
                    setImageUrl(baseUrl + '/' + userData.profile_img);
                }

                const isSpecialOccassion = userData.is_special_occassion;

                setSpecialOccassionCategory(isSpecialOccassion === 0 || isSpecialOccassion === null ? false : true);
                setCategoryPay(isSpecialOccassion === 1 ? false : true);

                const bankAccountStr = userData.bank_account ? userData.bank_account.toString() : '';
                setSelectedBankAccount(bankAccountStr);

                form.setFieldsValue(userData);



            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {

            values.is_special_occassion = showSpecialOccassionCategory ? 1 : 0;  // or values.is_special_occassion ? "yes" : "no"  
            values.user_id = userId;
            const formData = new FormData();
            Object.keys(values).forEach(key => {
                // console.log(key, values[key]);
                formData.append(key, values[key] !== undefined ? values[key] : '');
            });

            const bankAccountValue = accountOptions[values.bank_account];
            if (bankAccountValue) {
                values.bank_account = bankAccountValue;
            }


            if (file) {
                formData.append("file", file);
            }



            if (transactionDate) {
                const formattedDate = moment(transactionDate).format('YYYY-MM-DD');
                values.transaction_date = formattedDate;
            } else {
                values.transaction_date = '';
            }

            if (ereciept_date) {
                const formattedDate = moment(ereciept_date).format('YYYY-MM-DD');
                values.ereciept_date = formattedDate;
            } else {
                values.ereciept_date = '';
            }





            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/payments/update/${id}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Payment updated successfully!',
                        placement: 'topRight',
                        duration: 1.5
                    });
                    // history.push('/payments-history');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                const response = await axios.post(`${baseUrl}/payments/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Payment added successfully!',
                        placement: 'topRight'
                    });

                    //  setAddedUserData(values); // Save added payments data
                    //  setIsModalVisible(true); // Show modal
                    form.resetFields();
                    // history.push('/payments');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const getPaidMonths = async (userId) => {
        if (userId) {
            const response = await axios.get(
                `${baseUrl}/payments/overlap-check?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200) {
                const paidMonths = response.data.result.map(date => moment(date, 'YYYY-MM-DD').add(15, 'days'));
                setPaidMonths(paidMonths);

                if (response.data?.today?.length > 0) {
                    message.error("Today date payment already exit.");
                }

            } else {
                // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

            }
        }
    };



    //For model show data
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addedUserData, setAddedUserData] = useState(null);
    const handleModalClose = () => {
        setIsModalVisible(false);
        setAddedUserData(null);
        // history.push('/payments-history');
    };
    const userModalContent = addedUserData ? (
        <div>
            <p><strong>Phone No.:</strong> {addedUserData.phone_no}</p>
            {/* Add more fields as required */}
        </div>
    ) : null;

    const durationOptions = Array.from({ length: 12 }, (_, index) => index + 1);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [showAmountDuration, setShowAmountDuration] = useState(false);

    const handlePhoneNumberChange = async (e) => {
        const value = e.target.value;
        if (/^\d{0,12}$/.test(value)) {
            setPhoneNumber(value);
            if (value.length >= 10) {
                await checkPhoneNumber(value); // Call the async function to check the phone number
            }
        }
    }

    const checkPhoneNumber = async (phone, type = '') => {
        try {
            const response = await axios.post(
                `${baseUrl}/payments/checkUserPhone`,
                { phone_no: phone },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) {
                message.success('This is an already existing user. Please add their payment details.');
                const { phone_no, name_eng, father_name_en, relation_en, name_hn, relation_hn, father_name_hn, announced_amount, payment_type_user, announced_type } = response.data.details;
                form.setFieldsValue({
                    name_eng: name_eng,
                    father_name_en: father_name_en,
                    relation_en: relation_en,
                    father_name_hn: father_name_hn,
                    name_hn: name_hn,
                    relation_hn: relation_hn,
                    amount_announce: announced_amount,
                    duration_type: announced_type,
                });

                if (type == 'view') {
                    setModalViewVisible(true);
                    setAddedUserData(response.data.details); // Save added payments data
                }

            } else {
                //message.success("This is a new user. Please add their payment details so the system can create them automatically.");
                form.setFieldsValue({
                    name_eng: null,
                    father_name_en: null,
                    relation_en: null,
                    father_name_hn: null,
                    name_hn: null,
                    relation_hn: null,
                    amount_announce: null,
                    duration_type: null,
                });
            }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const checkPhoneNumberView = async (phone, type = '') => {
        try {
            const response = await axios.post(
                `${baseUrl}/payments/checkUserByIdView`,
                { id: userId },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) {
                if (type == 'view') {
                    const phone_no = form.getFieldValue('phone_no');

                    // setModalViewVisible(true);
                    //setAddedUserData(response.data.details); // Save added payments data 
                    setAddEditUserDetailsForm(response.data.details, phone_no);
                }

            } else {
                // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

            }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const handlePaymentTypeChange = async (value) => {

    }





    const handleSpecialOccassionCategory = (e) => {
        const isChecked = e.target.checked;
        setSpecialOccassionCategory(isChecked);
        setCategoryPay(!isChecked);
    }



    const [countryCode, setCountryCode] = useState('91');
    const handleCountryCodeChange = (value) => {
        setCountryCode(value);
        form.setFieldsValue({ country_code: value });
    };
    const [modalViewVisible, setModalViewVisible] = useState(false); // State to manage the visibility of the modal 
    const onViewButtonClick = async () => {
        if (phoneNumber.length >= 10) {
            await checkPhoneNumberView(phoneNumber, 'view'); // Call the async function to check the phone number
        }
    };


    const handleViewModalClose = () => {
        setModalViewVisible(false); // Close modal
    };

    const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);

    const [AddEditUserDetailsForm, setAddEditUserDetailsForm] = useState(null);

    const validatePhoneNumber = (number) => {
        const phoneRegex = /^[0-9]{10}$/; // Adjust the regex based on the phone number validation rules
        return phoneRegex.test(number);
    };

    const AddUserDetails = async () => {
        const values = await form.validateFields(['phone_no']);
        const phoneNumber = values.phone_no;
        setAddUserDetailsModel(true)
        if (phoneNumber.length >= 10) {
            await checkPhoneNumberView(phoneNumber, 'view'); // Call the async function to check the phone number
        }
        setuserBasicDetail({
            'phone_no': phoneNumber,
            'country_code': countryCode,
            'name_eng': form.getFieldValue('name_eng'),
            'relation_en': form.getFieldValue('relation_en'),
            'father_name_en': form.getFieldValue('father_name_en'),
            'name_hn': form.getFieldValue('name_hn'),
            'relation_hn': form.getFieldValue('relation_hn'),
            'father_name_hn': form.getFieldValue('father_name_hn')
        })
    }
    const AddUserDetailsClose = () => {
        setAddUserDetailsModel(false)
    }
    const containerRef = useRef(null);

    function onChange(date, dateString) {
        console.log(date, dateString);
    }

    const onMonthChange = (date, dateString, fieldName) => {
        const year = dateString.split('-')[1];
        const month = dateString.split('-')[0];
        const fullDate = `${year}-${month}-01`; // Format as YYYY-MM-01

        form.setFieldsValue(prev => ({
            ...prev,
            [fieldName]: fullDate
        }));
    };

    const handleDateChange = (date, dateString) => {
        console.log("date", date);
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            console.log("formattedDate", formattedDate);
            setTransactionDate(formattedDate)
        } else {
            setTransactionDate(null);
        }
    };

    const handleDateChangeEdate = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setEreciept_date(formattedDate)
        } else {
            setEreciept_date(null);
        }
    };



    // Mapping of account names to numeric values
    const accountOptions = {
        'ABRSVS(SHIKSHA NIDHI)-9078(SBI)': '1',
        'ABRSVS(M_GAUSHALA)-4937(SBI)': '2',
        'ABRSVS(ENVIRONMENT)-9598(AXIX)': '3',
        'SKSS_BADLI(80G)': '4',
        'SSS(80G)': '5',
        'ANY OTHER': '6',
    };

    // Mapping between English and Hindi values
    const relationMapping = {
        "Son of Mr.": "पुत्र श्री",
        "Daughter of Mr.": "सुपुत्री श्री",
        "Wife of Mr.": "पत्नी श्री",
        "Adopted Son of Mr.": "गोद पुत्र श्री"
    };

    // Function to handle change
    const handleRelationChange = (value) => {
        const hindiValue = relationMapping[value];
        form.setFieldsValue({
            relation_en: value,
            relation_hn: hindiValue
        });
    };

    const monthCellRender = (date) => {
        const isPaid = paidMonths.some(
            (paidMonth) => paidMonth.year() === date.year() && paidMonth.month() === date.month()
        );

        return (
            <div style={{ backgroundColor: isPaid ? '#d4edda' : '', color: isPaid ? 'green' : 'inherit', cursor: isPaid ? 'not-allowed' : 'pointer' }}>
                {date.format('MMM')}
            </div>
        );
    };

    const disabledDate = (current) => {
        return paidMonths.some(
            (paidMonth) => current.year() === paidMonth.year() && current.month() === paidMonth.month()
        );
    };

    const countryCodes = [
        "91", "93", "355", "213", "376", "244", "54", "374", "297", "247", "61", "43", "994",
        "973", "880", "375", "32", "501", "229", "1441", "975", "591", "387", "267",
        "55", "1,284", "673", "359", "226", "95", "257", "855", "237", "1", "238",
        "1,345", "236", "235", "56", "86", "61", "61", "57", "269", "242", "682",
        "506", "385", "53", "357", "420", "243", "45", "246", "253", "1,767", "593",
        "20", "503", "240", "291", "372", "251", "500", "298", "679", "358", "33",
        "594", "689", "241", "220", "995", "49", "233", "350", "30", "299", "1,473",
        "590", "1671", "502", "224", "245", "592", "509", "39", "504", "852", "36",
        "354", "62", "98", "964", "353", "44", "972", "39", "225", "1,876",
        "81", "44", "962", "7", "254", "686", "965", "996", "856", "371", "961",
        "266", "231", "218", "423", "370", "352", "853", "389", "261", "265", "60",
        "960", "223", "356", "692", "596", "222", "230", "262", "52", "691", "373",
        "377", "976", "382", "1,664", "212", "258", "264", "674", "977", "31", "599",
        "687", "64", "505", "227", "234", "683", "672", "850", "1670", "47", "968",
        "92", "680", "970", "507", "675", "595", "51", "63", "870", "48", "351",
        "974", "242", "262", "40", "7", "250", "590", "290", "1869", "1,758", "590",
        "508", "1,784", "685", "378", "239", "966", "221", "381", "248", "232", "65",
        "1,721", "421", "386", "677", "252", "27", "82", "211", "34", "94", "249",
        "597", "47", "268", "46", "41", "963", "886", "992", "255", "66", "670",
        "228", "690", "676", "1868", "216", "90", "993", "1,649", "688", "256",
        "380", "971", "44", "1", "598", "1340", "998", "678", "58", "84", "681",
        "212", "967", "260", "263"
    ];

    const handleReciptDateChange = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setEreciept_date(formattedDate)
            form.setFieldsValue({ ereciept_date: date });
        } else {
            setEreciept_date(null);
            form.setFieldsValue({ ereciept_date: null });
        }
    };

    const handlePaymentModeChange = (value) => {
        // Show E-Receipt fields if the payment mode is Cash, Website-Rozapay, or Application-Rozapay
        const modesToShow = ['Cash', 'Website-Rozapay', 'Application-Rozapay'];
        setShowEReceiptFields(modesToShow.includes(value));
    };

   

    return (


        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={isUpdateMode ? "Update Payment" : "Add Payment/भुगतान जोड़ें"}
                    >
                        <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item className="phone_no" label="Phone No." name="phone_no"
                                        rules={[{ required: true, message: 'Please enter phone no' },
                                        {
                                            pattern: /^\d{10,12}$/,
                                            message: 'Phone no must be between 10 and 12 digits!',
                                        }
                                        ]} >
                                        <Input readOnly onChange={handlePhoneNumberChange} placeholder="Phone no."
                                            addonBefore={
                                                <Select
                                                    defaultValue={countryCode}
                                                    onChange={handleCountryCodeChange}
                                                    style={{ width: 80 }}
                                                >
                                                    {countryCodes.map((code) => (
                                                        <Option key={code} value={code}>+{code}</Option>
                                                    ))}
                                                </Select>
                                            }
                                            prefix={<PhoneOutlined />}

                                        />
                                    </Item>
                                </Col>
                                <Col xs={24}><Title level={5}>Add User Detail</Title> </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Name" name="name_eng" rules={[{ required: true, message: 'Please enter name' }]}>
                                        <Input placeholder="User Name" prefix={<UserOutlined />} />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={3}>
                                    <Item label="Relation" name="relation_en" >
                                        <Select placeholder="Relation" onChange={handleRelationChange}>
                                            <Option value="Son of Mr.">Son of Mr.</Option>
                                            <Option value="Daughter of Mr.">Daughter of Mr.</Option>
                                            <Option value="Wife of Mr.">Wife of Mr.</Option>
                                            <Option value="Adopted Son of Mr.">Adopted Son of Mr.</Option>

                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Father Name" name="father_name_en" >
                                        <Input placeholder="Father Name" prefix={<UserOutlined />} />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="नाम" name="name_hn" >
                                        <Input placeholder="नाम दर्ज करें।" prefix={<UserOutlined />} />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={3}>
                                    <Item label="संबंध" name="relation_hn" >
                                        <Select placeholder="संबंध चुनें।">
                                            <Option value="पुत्र श्री">पुत्र श्री</Option>
                                            <Option value="सुपुत्री श्री">सुपुत्री श्री</Option>
                                            <Option value="पत्नी श्री">पत्नी श्री</Option>
                                            <Option value="गोद पुत्र श्री">गोद पुत्र श्री</Option>
                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="पिता का नाम" name="father_name_hn" >
                                        <Input placeholder="पिता का नाम दर्ज करें।" prefix={<UserOutlined />} />
                                    </Item>
                                </Col>


                                <Button onClick={AddUserDetails} type="primary">Add/Edit/View Details</Button>

                                <Col xs={24}><Title level={5}>Add New Payment Detail</Title> </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item label="Account" name="bank_account"
                                        rules={[{ required: true, message: 'Select Bank Account' }]}
                                    >
                                        <Select onChange={handlePaymentTypeChange}
                                            className="bank_account"
                                            value={selectedBankAccount}
                                            placeholder="Select Account" >
                                            {Object.keys(accountOptions).map((label) => (
                                                <Option key={label} value={label}>
                                                    {label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Item>

                                    <Item label="Is Special Occassion" name="is_special_occassion">
                                        <Checkbox
                                            checked={showSpecialOccassionCategory}
                                            onChange={handleSpecialOccassionCategory}
                                            className="custom-checkbox occansanscheck"
                                        > Is Special Occasion
                                        </Checkbox>
                                    </Item>
                                </Col>





                                {showSpecialOccassionCategory && (
                                    <>
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Special Occassion" name="special_occassion_category"
                                                rules={[{ required: true, message: 'Select Special Occassion' }]}>
                                                <Select placeholder="Special Occassion">
                                                    <Option value="Birthday">Birthday</Option>
                                                    <Option value="Anngegment">Anngegment</Option>
                                                    <Option value="Marriage">Marriage</Option>
                                                    <Option value="Anniversary">Anniversary</Option>
                                                    <Option value="Bahan-Beti-Sammelan">Bahan-Beti Sammelan</Option>
                                                    <Option value="Any-Other">Any Other</Option>
                                                </Select>
                                            </Item>
                                        </Col>
                                    </>
                                )}
                                <Divider />
                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Amount Rs." name="amount" rules={[{ required: true, message: 'Please enter amount' }]}>
                                        <InputNumber className="input_number_type" placeholder="Enter Amount Rs." prefix={<MoneyCollectOutlined />} />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Tnx Detail (UTR/ID)" name="utr_number">
                                        <Input placeholder="(UTR/ID)" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Tnx Date" >
                                        <DatePicker onChange={handleDateChange} value={transactionDate && moment(transactionDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Tnx Date" prefix={<CalendarOutlined />} />

                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Mode of Payment" name="pyament_mode_type" >
                                        <Select onChange={handlePaymentModeChange} placeholder="Mode of Payment">
                                            <Option value="UPI">UPI</Option>
                                            <Option value="Cash">Cash</Option>
                                            <Option value="Cheque">Cheque</Option>
                                            <Option value="RTGS-NEFT">RTGS/NEFT</Option>
                                            <Option value="Website-Rozapay">Website-Rozapay</Option>
                                            <Option value="Application-Rozapay">Application-Rozapay</Option>
                                        </Select>
                                    </Item>
                                </Col>

                                {showCategoryPay && (
                                    <>
                                        <Divider />

                                        <Col xs={24} sm={12} lg={6}>
                                            {/* <Form.Item
                                                label="Month Covered"
                                                name="month_range"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Select the month range',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || value.length !== 2) {
                                                                return Promise.reject(new Error('Please select both start and end months'));
                                                            }
                                                            const [start, end] = value;
                                                            if (start.isBefore(end) || start.isSame(end)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('End month should be greater than or equal to start month'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <RangePicker
                                                    picker="month"
                                                    format="MM-YYYY"
                                                    placeholder={['Start Month', 'End Month']}
                                                    style={{ width: '100%' }}
                                                 
                                                />
                                            </Form.Item> */}

                                            <Form.Item
                                                label="Month Covered"
                                                name="month_range"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Select the month range',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || value.length !== 2) {
                                                                return Promise.reject(new Error('Please select both start and end months'));
                                                            }
                                                            const [start, end] = value;
                                                            if (start.isBefore(end) || start.isSame(end)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('End month should be greater than or equal to start month'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <RangePicker
                                                    picker="month"
                                                    format="MM-YYYY"
                                                    placeholder={['Start Month', 'End Month']}
                                                    style={{ width: '100%' }}
                                                    monthCellRender={monthCellRender} // Customize month cell rendering
                                                    disabledDate={disabledDate} // Disable already paid months
                                                />
                                            </Form.Item>

                                            {/* <span className="text-success" >{messageOverlap}</span> */}
                                        </Col>
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Monthly Share(Rs.)" name="month_share_amount" rules={[{ required: true, message: 'Please select verified status' }]}>
                                                <InputNumber className="input_number_type" placeholder="Monthly Share(Rs.)" />
                                            </Item>
                                        </Col>

                                    </>
                                )}

                                <Divider />

                                <Col xs={24} sm={12} lg={4}>
                                    <Item label="Payment Status" name="payment_virify" rules={[{ required: true, message: 'Please select verified status' }]} >
                                        <Select placeholder="Please select Verified Status">
                                            <Option value="pending">Pending</Option>
                                            <Option value="verified">Verified</Option>
                                            <Option value="failed">Failed</Option>

                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Remark" name="remarks">
                                        <Input placeholder="Remark" />
                                    </Item>
                                </Col>

                                {showEReceiptFields && (
                                    <>
                                        <Divider /> 
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="E-Reciept No" name="e_reciept_no">
                                                <Input placeholder="E-Reciept No" />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="E-Reciept Date" >
                                                <DatePicker onChange={handleDateChangeEdate} value={ereciept_date && moment(ereciept_date)} className="filter_dob" format="DD-MM-YYYY" placeholder="E-Reciept Date" prefix={<CalendarOutlined />} />

                                            </Item>
                                        </Col>
                                    </>
                                )}

                            </Row>
                            <Row>
                                <Col xs={24} sm={12} lg={4}>
                                    <Item>
                                        <Button type="primary" htmlType="submit">
                                            {isUpdateMode ? "Update Payment" : "Add Payment"}
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Modal width={1000}
                title="Payment Information"
                open={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <div className="scrollable-modal-content">
                    {addedUserData && <UserModalContent userData={addedUserData} />}
                </div>

            </Modal>

            <Modal width={1000}
                title="User Profile"
                open={modalViewVisible}
                onOk={handleViewModalClose}
                onCancel={handleViewModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleViewModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <div className="scrollable-modal-content">
                    {addedUserData && <UserModalContent userData={addedUserData} />}
                </div>

            </Modal>



            <Drawer
                title="User Details"
                width={1000}
                onClose={AddUserDetailsClose}
                open={AddUserDetailsFormModel}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                maskClosable={false}
            >


                <AddUserDetailsModel userData={AddEditUserDetailsForm} userBasicDetail={userBasicDetail} />

            </Drawer>




        </div>
    );
}

export default PaymentsEditForm;