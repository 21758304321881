import { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import moment from 'moment';
import axios from "axios";
import { Row, Col, Button, Space, notification, Form, Input, DatePicker, Table, Collapse, Modal } from "antd";
import '../../assets/styles/custom_style.css';
import { CalendarOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Item } = Form;
const { Panel } = Collapse;
const { confirm } = Modal;

const SocialStatus = ({ user_id }) => {
    const [form] = Form.useForm();
    const [recordId, setRecordId] = useState(null);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isTableVisible, setIsTableVisible] = useState(true);

    useEffect(() => {
        setUserData([]);
        setIsTableVisible(false);
        // Fetch user data or any other initial data here
        if (user_id) {
            fetchSocialStatusData(user_id);
        }
    }, [user_id]);

    async function fetchSocialStatusData(user_id) {
        try {
            const response = await axios.get(`${baseUrl}/social-status/list/${user_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.data.status) {
                setUserData(response.data.result);
                setIsTableVisible(response.data?.result?.length === 0 ? false : true)
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {
            // Check if at least one field is filled
            const isEmpty = Object.values(values).every(x => x === null || x === '' || x === undefined);

            if (isEmpty) {
                notification.info({
                    message: 'Info',
                    description: "Please fill out at least one field.",
                    placement: 'topRight'
                });
                return; // Exit the function if no fields are filled
            }
            if (user_id) {
                values.user_id = user_id;

                let response;
                if (isUpdateMode) {
                    response = await axios.patch(`${baseUrl}/social-status/update/${recordId}`, values, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                } else {
                    response = await axios.post(`${baseUrl}/social-status/create`, values, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                }

                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: isUpdateMode ? 'Social status updated successfully!' : 'Social status added successfully!',
                        placement: 'topRight'
                    });
                    setIsTableVisible(true);
                    fetchSocialStatusData(user_id); // Assuming you have a fetch function for social status data
                    form.resetFields(); // Reset form fields after successful submission
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                notification.info({
                    message: 'Info',
                    description: "Please add basic information",
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const handleEdit = (record) => {
        form.setFieldsValue({
            sn_organisation_name: record.sn_organisation_name,
            sn_post: record.sn_post,
            sn_duration_start: record.sn_duration_start ? moment(record.sn_duration_start) : null,
            sn_duration_end: record.sn_duration_end ? moment(record.sn_duration_end) : null,
            sn_organisation_address: record.sn_organisation_address,
        });
        setRecordId(record.id); // Assuming id is available in the record object
        setIsUpdateMode(true);
        setIsTableVisible(false); // Hide table and show form for editing
    };

    const showDeleteConfirm = (record) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleDelete = async (record) => {
        try {
            const response = await axios.delete(`${baseUrl}/social-status/delete/${record.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Record deleted successfully!',
                    placement: 'topRight'
                });
                fetchSocialStatusData(user_id); // Refresh table data after deletion
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const columns = [
        {
            title: 'Organisation Name',
            dataIndex: 'sn_organisation_name',
            key: 'organisation_name',
        },
        {
            title: 'Post',
            dataIndex: 'sn_post',
            key: 'post',
        },
        {
            title: 'Duration Start',
            dataIndex: 'sn_duration_start',
            key: 'duration_start',
            render: (text) => (text ? moment(text).format('YYYY-MM-DD') : 'N/A'), // Format date
        },
        {
            title: 'Duration End',
            dataIndex: 'sn_duration_end',
            key: 'duration_end',
            render: (text) => (text ? moment(text).format('YYYY-MM-DD') : 'N/A'), // Format date
        },
        {
            title: 'Organisation Address',
            dataIndex: 'sn_organisation_address',
            key: 'organisation_address',
        },
        {
            title: 'Mobile No.',
            dataIndex: 'org_phone_no',
            key: 'org_phone_no',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div className="button-container">
                    <Space size="middle">
                        <button className="update-btn" onClick={() => handleEdit(record)}> <EditOutlined /></button>
                        <button className="delete-btn" onClick={() => showDeleteConfirm(record)}><DeleteOutlined /></button>
                    </Space>
                </div>
            ),
        },
        // Add more columns as needed
    ];

    const changeTableView = async () => {
        if (isTableVisible) {
            setIsTableVisible(!isTableVisible)
            form.setFieldsValue({
                sn_organisation_name: "",
                sn_post: "",
                sn_duration_start: "",
                sn_duration_end: "",
                sn_organisation_address: "",
            });
            setRecordId(null); // Assuming id is available in the record object
            setIsUpdateMode(false);
        } else {
            setIsTableVisible(!isTableVisible)
            // setIsTableVisible(false); // Hide table and show form for editing
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Button
                        type="primary"
                        onClick={() => changeTableView()}
                        style={{ marginBottom: '20px', float: 'right' }}
                    >
                        {isTableVisible ? 'Add' : 'Show Table'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="tabled">
                        {isTableVisible ? (
                            <Table
                                columns={columns}
                                dataSource={userData}
                                pagination={false}
                                scroll={{ x: true }}
                            />
                        ) : (
                            <Form style={{ "padding": "0px" }} form={form} onFinish={onFinish} layout="vertical">
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Org. Name" name="sn_organisation_name">
                                            <Input placeholder="Org. name" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Post" name="sn_post">
                                            <Input placeholder="Post" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Duration Start" name="sn_duration_start">
                                            <DatePicker className="filter_dob" format="DD-MM-YYYY" placeholder="Select start date" prefix={<CalendarOutlined />} />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Duration End" name="sn_duration_end">
                                            <DatePicker className="filter_dob" format="DD-MM-YYYY" placeholder="Select end date" prefix={<CalendarOutlined />} />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Org. Address" name="sn_organisation_address">
                                            <Input placeholder="Org. address" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Org.Mobile No" name="org_phone_no">
                                            <Input placeholder="Mobile No." />
                                        </Item>
                                    </Col>


                                </Row>
                                <div className="user_add_form_details">
                                    <Space>
                                        <Button type="primary" onClick={() => form.submit()}>Save Detail</Button>
                                    </Space>
                                </div>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default SocialStatus;
