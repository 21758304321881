// import React, { Component } from "react";
// import { Link, useHistory } from "react-router-dom";
// import axios from 'axios';
// import {
//   Layout,
//   Button,
//   Row,
//   Col,
//   Typography,
//   Form,
//   Input,
//   Switch,
// } from "antd";
// import signinbg from "../assets/images/img-signin.jpg";
// import { message } from 'antd';
// import { baseUrl } from "../config";

// function onChange(checked) {
//   console.log(`switch to ${checked}`);
// }
// const { Title } = Typography;
// const { Content } = Layout;


// function SignIn() {

//   const history = useHistory();

//   const onFinish = async (values) => {
//     try {
//       const response = await axios.post(`${baseUrl}/login`, values);
//       console.log("Success:", response.data);
//       if (response.data.status === 'success') {
//         message.success(response.data.message);
//         localStorage.setItem('accessToken', response.data.user.token);
//         localStorage.setItem('permission', JSON.stringify(response.data.user.permission));
//         localStorage.setItem('userType', response.data.user.userType);
//         localStorage.setItem('firstName', response.data.user.firstName);
//         localStorage.setItem('lastName', response.data.user.lastName === undefined ? '' : response.data.user.lastName);
//         localStorage.setItem('userId', response.data.user._id);
//         localStorage.setItem('email', response.data.user.local.email);
//         history.push('/dashboard');
//       } else {
//         message.error(response.data.message);
//       }
//       // Do something with the successful response, like updating state or redirecting the user
//     } catch (error) {
//       console.log("Failed:", error.response.data);
//       message.error(error.response.data.message);
//       // Handle the error, display a message to the user, or perform any other action
//     }
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//     // Handle the failed form submission
//   };
//   return (
//     <>
//       <Layout className="layout-default layout-signin">

//         <Content className="signin">
//           <Row gutter={[24, 0]} justify="space-around">
//             <Col
//               xs={{ span: 24, offset: 0 }}
//               lg={{ span: 6, offset: 2 }}
//               md={{ span: 12 }}
//             >
//               <Title className="mb-15">Sign In</Title>
//               <Title className="font-regular text-muted" level={5}>
//                 Enter your email and password to sign in
//               </Title>
//               <Form
//                 onFinish={onFinish}
//                 onFinishFailed={onFinishFailed}
//                 layout="vertical"
//                 className="row-col"
//               >
//                 <Form.Item
//                   className="username"
//                   label="Email"
//                   name="email"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please input your email!",
//                     },
//                   ]}
//                 >
//                   <Input placeholder="Email" />
//                 </Form.Item>

//                 <Form.Item
//                   className="username"
//                   label="Password"
//                   name="password"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please input your password!",
//                     },
//                   ]}
//                 >
//                   <Input type="password" placeholder="Password" />
//                 </Form.Item>

//                 <Form.Item
//                   name="remember"
//                   className="aligin-center"
//                   valuePropName="checked"
//                 >
//                   <Switch defaultChecked onChange={onChange} />
//                   Remember me
//                 </Form.Item>

//                 <Form.Item>
//                   <Button
//                     type="primary"
//                     htmlType="submit"
//                     style={{ width: "100%" }}
//                   >
//                     SIGN IN
//                   </Button>
//                 </Form.Item>
//                 <p className="font-semibold text-muted">
//                   Don't have an account?{" "}
//                   <Link to="/sign-up" className="text-dark font-bold">
//                     Sign Up
//                   </Link>
//                 </p>
//               </Form>
//             </Col>
//             <Col
//               className="sign-img"
//               style={{ padding: 12 }}
//               xs={{ span: 24 }}
//               lg={{ span: 12 }}
//               md={{ span: 12 }}
//             >
//               <img src={signinbg} alt="" />
//             </Col>
//           </Row>
//         </Content>
//       </Layout>
//     </>
//   );
// }

// export default SignIn;

import React from "react";
import {
  Layout,
  Button,
  Card,
  Form,
  Input,
  message
} from "antd";
import axios from 'axios';
import logo1 from "../assets/images/logo1.png";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../config";


const { Content } = Layout;

function SignIn() {
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      const response = await axios.post(`${baseUrl}/login`, values);
      console.log("Success:", response.data);
      if (response.data.status === 'success') {
        message.success(response.data.message);
        localStorage.setItem('accessToken', response.data.user.token);
        localStorage.setItem('permission', JSON.stringify(response.data.user.permission));
        localStorage.setItem('userType', response.data.user.userType);
        localStorage.setItem('firstName', response.data.user.firstName);
        localStorage.setItem('lastName', response.data.user.lastName === undefined ? '' : response.data.user.lastName);
        localStorage.setItem('userId', response.data.user._id);
        localStorage.setItem('email', response.data.user.local.email);
        history.push('/dashboard');
      } else {
        message.error(response.data.message);
      }
      // Do something with the successful response, like updating state or redirecting the user
    } catch (error) {
      console.log("Failed:", error.response.data);
      message.error(error.response.data.message);
      // Handle the error, display a message to the user, or perform any other action
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // Handle the failed form submission
  };
  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">


        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              {/* <Title>LOGIN</Title> */}
              {/* <p className="text-lg">
                  Use these awesome forms to login or create new account in your
                  project for free.
                </p> */}
            </div>
          </div>

          <Card
            className="card-signup header-solid ant-card pt-0"
            title={<img className="login-image-logo" src={logo1} />}
            bordered="false"
          >
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >

              <Form.Item
                className="username"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                className="username"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                    I agree the{" "}
                    <a href="#pablo" className="font-bold text-dark">
                      Terms and Conditions
                    </a>
                  </Checkbox>
                </Form.Item> */}

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
            {/* <p className="font-semibold text-muted text-center">
                Already have an account?{" "}
                <Link to="/sign-in" className="font-bold text-dark">
                  Sign In
                </Link>
              </p> */}
          </Card>
        </Content>

      </div>
    </>
  );
}

export default SignIn;



