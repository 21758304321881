import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    Typography,
    notification,
    Form,
    Input,
    Select,
    DatePicker
} from "antd";
import axios from "axios";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function StaffForm() {
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [userType, setUserType] = useState(""); // State to track selected userType
    const [txnDate, setTxnDate] = useState(moment().format('YYYY-MM-DD'));
 
    useEffect(() => {
        // Check if id exists to determine if it's an update mode
        if (id) {
            setIsUpdateMode(true);
            fetchRoleDetails();
        }
    }, [id]);



    const fetchRoleDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/suspense-entry/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);
                form.setFieldsValue(response.data.result); // Populate form fields with fetched data
                setTxnDate(response.data.result.txn_date ? response.data.result.txn_date : null) // Format the date if present)
                setUserType(response.data.result.userType); // Set the initial userType state
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
 
        try {
 
          
            if (txnDate) {
                const formattedDate = moment(txnDate).format('YYYY-MM-DD');
                values.txn_date = formattedDate;
            } else {
                values.txn_date = '';
            }

            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/suspense-entry/update/${id}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Suspense Entry updated successfully!',
                        placement: 'topRight'
                    });
                    history.push('/suspense-entries');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                const response = await axios.post(`${baseUrl}/suspense-entry/create`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Suspense Entry added successfully!',
                        placement: 'topRight'
                    });
                    form.resetFields();
                    history.push('/suspense-entries');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const handleUserTypeChange = (value) => {
        setUserType(value);
    };

    const handleDateChange = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setTxnDate(formattedDate)
        } else {
            setTxnDate(null);
        }
    };



    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={isUpdateMode ? "Update Suspense Entry" : "Add Suspense Entry"}
                    >
                        <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={4} lg={4}>
                                    <Item label="Tnx Date" >
                                    <DatePicker onChange={handleDateChange} value={txnDate && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Txn Date" prefix={<CalendarOutlined />} />

                                    </Item>
                                </Col>

                                <Col xs={24} sm={4} lg={4}>
                                    <Item
                                        label="Ref No./Cheque No."
                                        name="cheque_no"
                                    >
                                        <Input placeholder="Enter Ref No./Cheque No." />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={4} lg={4}>
                                    <Item
                                        label="Branch Code."
                                        name="branch_code"
                                    >
                                        <Input placeholder="Enter Branch Code." />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={6} lg={6}>
                                    <Item
                                        label="Amount(Credit)"
                                        name="amount"
                                        rules={[{ required: true, message: 'Please enter Amount(Credit)' }]}
                                    >
                                        <Input type="number" placeholder="Enter Amount(Credit)" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={6} lg={6}>
                                    <Item
                                        label="Tnx Detail (UTR/ID)"
                                        name="tnx_detail"
                                        rules={[{ required: true, message: 'Please enter Tnx Detail (UTR/ID)' }]}
                                    >
                                        <Input placeholder="Enter Tnx Detail (UTR/ID)" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Description"
                                        name="description"
                                    >
                                        <TextArea placeholder="Enter Description" rows={2} />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Remark"
                                        name="remark"
                                    >
                                        <TextArea placeholder="Enter Remark" rows={2} />
                                    </Item>
                                </Col>

                            </Row>
                            <Row style={{ textAlign: 'right' }}>
                                <Col xs={24} sm={24} lg={24}>
                                    <Item>
                                        <Button type="primary" htmlType="submit">
                                            {isUpdateMode ? "Update" : "Save"}
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default StaffForm;
