import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import userDummy from "../../assets/images/dummy-user.png";

import {
  Row, Col, Card, Button, Tabs, List, Descriptions, Avatar, Radio, Switch, Upload, message, notification, Select, Typography, Form, Modal
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined, UserOutlined
} from "@ant-design/icons";

import BgProfile from "../../assets/images/bg-profile.jpg";
import profilavatar from "../../assets/images/face-1.jpg";
import convesionImg from "../../assets/images/face-3.jpg";
import convesionImg2 from "../../assets/images/face-4.jpg";
import convesionImg3 from "../../assets/images/face-5.jpeg";
import convesionImg4 from "../../assets/images/face-6.jpeg";
import convesionImg5 from "../../assets/images/face-2.jpg";
import project1 from "../../assets/images/home-decor-1.jpeg";
import project2 from "../../assets/images/home-decor-2.jpeg";
import project3 from "../../assets/images/home-decor-3.jpeg";
import Invoice from "../../components/Invoice";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

function ViewPayments() {

  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [userData, setUserData] = useState({});
  const printRef = useRef();



  useEffect(() => {
    // Check if id exists to determine if it's an update mode
    if (id) {
      fetchUserDetails();
    }
  }, [id]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/payments/read/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        // setRoleData(response.data.result);
        setUserData(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };


  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [isInvoiceModal, setIsInvoiceModal] = useState(false);

  const showModalInvoice = (image) => {
    setIsInvoiceModal(true);
  };

  const handleCancelInvoice = () => {
    setIsInvoiceModal(false);
  };

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };


  const handleVerifyPayment = async (paymentId) => {
    Modal.confirm({
      title: 'Are you sure you want to verify this payment?',
      onOk: async () => {
        try {
          const response = await axios.patch(`${baseUrl}/payments/paymentStatusVerify/${paymentId}`, { payment_virify: 'verified' }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
          });

          if (response.status === 200) {
            fetchUserDetails();
            notification.success({
              message: 'Success',
              description: 'Payment verified successfully',
              placement: 'topRight',
              duration: 1.5
            });
          }
        } catch (error) {
          notification.info({
            message: 'Info',
            description: "Failed to verify payment",
            placement: 'topRight'
          });

        }
      },
    });
  }


  return (
    <>

      <Card
        className="card-profile-head"
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar
                  className="shape-avatar"
                  shape="square"
                  size={80}
                  src={userData.profile_img ? `${baseUrl}/${userData.profile_img}` : userDummy}
                  icon={!userData.profile_img && <UserOutlined />}
                  onClick={() => showModal(userData.profile_img ? `${baseUrl}/${userData.profile_img}` : userDummy)}
                  style={{ cursor: 'pointer' }}
                />
                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{userData.name_eng}</h4>
                  <p>{userData.phone_no}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >

            </Col>
          </Row>
        }
      >
        <Row>


          <Col span={24}>
            <Descriptions title="Payment Details" bordered column={1}>
              <Descriptions.Item label="Account">
                {userData.bank_account}
              </Descriptions.Item>
              {userData.special_occassion_category && (
                <Descriptions.Item label="Special Occasion">
                  {userData.special_occassion_category}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Amount (Rs)">
                Rs.{userData.amount}
              </Descriptions.Item>
              <Descriptions.Item label="Txn Detail (URT/ID)">
                {userData.utr_number}
              </Descriptions.Item>
              <Descriptions.Item label="Txn Date">
                {userData.transaction_date ? moment(userData.transaction_date).format('DD MMM YYYY') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Mode of Payment">
                {userData.pyament_mode_type}
              </Descriptions.Item>
              <Descriptions.Item label="Pay Entry Date">
                {userData.payment_entry_date ? moment(userData.payment_entry_date).format('DD MMMM YYYY') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Remark">
                {userData.remarks}
              </Descriptions.Item>



              <Descriptions.Item label="Payment Status">

                <div className="button-container">
                  {(userData.payment_virify === 'pending' || userData.payment_virify === 'failed') && (
                    <Button
                      type="primary"
                      style={{ backgroundColor: '#faad14', color: '#fff', borderColor: '#faad14' }}
                      danger={userData.payment_virify === 'failed'}
                      onClick={() => handleVerifyPayment(userData.payment_id)}
                    >
                      {userData.payment_virify === 'pending' ? 'Pending' : 'Failed '}
                    </Button>
                  )}
                  {userData.payment_virify === 'verified' && (
                    <Button type="primary" style={{ backgroundColor: 'green', color: '#fff', borderColor: 'green' }} disabled>
                      Verified
                    </Button>
                  )}
                </div>

              </Descriptions.Item>
            </Descriptions>
          </Col>


          {userData.account_id && userData.account_id == 1 && (
            <Col span={24} style={{ marginTop: '20px' }}>
              <Descriptions title="SHIKSHA NIDHI" bordered column={1}>



                {['Cash', 'Website-Rozapay', 'Application-Rozapay'].includes(userData?.pyament_mode_type)
                  ? (
                    <>

                      <Descriptions.Item label="E-Reciept No">
                        {userData.e_reciept_no}
                      </Descriptions.Item>

                      <Descriptions.Item label="E-Reciept Date">
                        {userData.e_reciept_date ? moment(userData.e_reciept_date).format('DD MMMM YYYY') : '-'}
                      </Descriptions.Item>
                    </>
                  )
                  : (
                    <>
                      <Descriptions.Item label="Receipt no.">
                        {userData.receipt_no ? userData.receipt_no : '-'}
                      </Descriptions.Item>
                    </>

                  )}



                <Descriptions.Item label="Month Covered Start">
                  {userData.start_month ? moment(userData.start_month).format('DD MMMM YYYY') : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Month Covered End">
                  {userData.end_month
                    ? moment(userData.end_month).add(1, 'months').format('DD MMMM YYYY')
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Duration (Months)">
                  {userData.duration_type}
                </Descriptions.Item>

                <Descriptions.Item label="Monthly Share (Rs)">
                  Rs.{userData.month_share_amount}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}



          <Col span={24} style={{ marginTop: '24px' }}>

            {userData?.account_id === 1 && userData?.payment_virify === "verified" &&
              !['Cash', 'Website-Rozapay', 'Application-Rozapay'].includes(userData?.pyament_mode_type)
              && (
                <>
                  <Button style={{ marginRight: '5px' }} title="View" onClick={showModalInvoice}>View Invoice</Button>
                  <Invoice userData={userData} btnShow={['img_download']} />
                  <Invoice userData={userData} btnShow={['pdf_download']} />
                  <Invoice userData={userData} btnShow={['pdf_print']} />

                  <Invoice userData={userData} btnShow={['whatsapp']} />
                </>
              )}


            {/* <Button title="Send" /> */}

          </Col>

        </Row>
      </Card>



      <Modal
        open={isInvoiceModal}
        footer={null}
        onCancel={handleCancelInvoice}
        width={767}
        closeIcon={null}
      >
        <Invoice userData={userData} displayView="block" />
      </Modal>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>
    </>
  );
}

export default ViewPayments;
