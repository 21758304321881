import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table,Tooltip , Button, Input, notification, Divider, Modal, Select, Avatar, Typography, Checkbox, DatePicker } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { DeleteOutlined, CalendarOutlined, SendOutlined, FileExcelOutlined, FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined, EyeOutlined, UserOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import FilterReport from "./FilterReport";
import * as XLSX from 'xlsx';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { RangePicker, MonthPicker } = DatePicker;

function BirthdayReport() {
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    phone: '',

  });


  useEffect(() => {
    const filterKeys = Object.keys(filters);

    const anyFilterHasMinLength = filterKeys.some(
      key => key !== 'createdBy' && filters[key].length >= 3
    );

    const allFiltersEmptyOrCreatedBy = filterKeys.every(
      key => key === 'createdBy' || !filters[key]
    );

    if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {
      console.log('Calling debouncedGetUserList');
      debouncedGetUserList(currentPage, limit, sortField, sortOrder, search, filters);
    }
  }, [filters, currentPage, limit, sortField, sortOrder, search]);

  useEffect(() => {
    getUserList();
  }, []);

  // Table columns definition
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      fixed: 'left',
      width: 50,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      fixed: 'left',
      // sorter: true,
      width: 200,
    },
    {
      title: "PHONE NO",
      dataIndex: "phone_no",
      key: "phone_no",
      // sorter: true,
    },
    {
      title: "FATHER NAME",
      dataIndex: "father_name",
      key: "father_name",
      // sorter: true,
    },
    {
      title: "BIRTHDAY DATE",
      dataIndex: "dob_date",
      key: "dob_date",
      // sorter: true,
    },
    {
      title: "Response",
      key: "dob_response",
      dataIndex: "dob_response",
      width: 200,
      render: (text, record) => (
        isEditing(record.key) ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input.TextArea
              value={editingResponse}
              onChange={(e) => setEditingResponse(e.target.value)}
              style={{ marginRight: 8, width: '300px', height: '60px' }} // Adjust width and height as needed
            />
            <Button
              type="primary"
              onClick={() => saveResponse(text.id)}
              style={{ marginRight: 8 }}
            ><SaveOutlined /></Button>
            <Button
              type="default"
              onClick={() => cancelEdit()}
            ><CloseOutlined /></Button>
          </div>
        ) : (
          <div
            onDoubleClick={() => editResponse(record.key, text.dob_response || '')}
            style={{ cursor: 'pointer', position: 'relative' }}
          >
            {text.dob_response || '-'}
            <EditOutlined onClick={() => editResponse(record.key, text.dob_response || '')} style={{ position: 'absolute', right: 0, top: 0, opacity: 0.5 }} />
          </div>
        )
      )
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      // sorter: true,
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      fixed: 'right',
    }

  ];

  const [editingKey, setEditingKey] = useState('');
  const [editingResponse, setEditingResponse] = useState('');
  const [editingResponseForBackup, setEditingResponseForBackup] = useState('');

  const isEditing = (key) => key === editingKey;

  const editResponse = (key, text) => {
    setEditingKey(key);
    setEditingResponse(text);
    setEditingResponseForBackup(text);
  };

  const cancelEdit = () => {
    setEditingKey('');
    setEditingResponse('');
    setEditingResponseForBackup('');
  };

  const saveResponse = async (key) => {
    try {
      if (editingResponse === editingResponseForBackup) {
        setEditingKey('');
        setEditingResponse('');
        setEditingResponseForBackup('');
      } else {
        const response = await axios.put(`${baseUrl}/users/updateDobResponse`, {
          user_id: key,
          latest_payment_response: editingResponse
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        });
        if (response.data.status) {
          notification.success({
            message: 'Success',
            description: 'Response updated successfully',
            placement: 'topRight'
          });
          getUserList(currentPage, limit, sortField, sortOrder, search, filters);
        } else {
          notification.error({
            message: 'Error',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.error({
        message: 'Error',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }

    setEditingKey('');
    setEditingResponse('');
    setEditingResponseForBackup('');
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/users/birthdayList?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);

        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleSearch = value => {
    setSearch(value);
    getUserList(currentPage, limit, sortField, sortOrder, value, filters);
  };

  const handleLimitChange = value => {
    setLimit(value);
    getUserList(1, value, sortField, sortOrder, search, filters);
  };

  const debouncedGetUserList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {
    getUserList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);


  const handleFilterChange = (field, value) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const exportToExcel = async () => {
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/users/birthdayList?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      const formattedData = response.data.result.map((user, index) => ({
        '#': (limit * (currentPage - 1)) + index + 1,
        'MEMBER NAME': `${user.name}`,
        'PHONE NO': `${user.phone_no}`,
        'FATHER NAME': `${user.father_name}`,
        'DOB': user.dob ? moment(user.dob).format('DD/MM/YYYY') : '-',
        'Response': user.dob_response,
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "BirthdayList");

      XLSX.writeFile(workbook, "BirthdayList.xlsx");

    } else {
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }

  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const sendMessage = async (userId) => {
    console.log(`Sending message to user with ID: ${userId}`);
    // Implement your message sending logic here
    const response = await axios.post(
      `${baseUrl}/sendMessage/birthdateSMS`,
      { user_id: userId },
      {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
      }
  );
  console.log(response)
  if (response.data.status === true ) {
    notification.success({
      message: 'Success',
      description: response.data.message,
      placement: 'topRight'
  });

  }else{
    notification.info({
      message: 'Info',
      description: response.data.message,
      placement: 'topRight'
  });

  }


  };
  const showConfirm = (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to send birthday message Now?',
      onOk() {
        sendMessage(userId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };


  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Birthday List"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}> <Title style={{ fontSize: 13 }} level={5}>Filter By:</Title>  </Col>
                      <Col span={4}>
                        <Input placeholder="Phone" value={filters.phone} onChange={e => handleFilterChange('phone', e.target.value)} />
                      </Col>
                      <Col span={4}>
                        <Input placeholder="Name" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} />
                      </Col>
                      <Col xs={4}>
                        <DatePicker className="filter_dob CustomInputSet"
                          format="DD/MM" value={filters.dob ? moment(filters.dob, 'DD/MM') : null}
                          onChange={(date, dateString) => handleFilterChange('dob', dateString)} placeholder="DOB (DD/MM)"
                        />
                      </Col>
                      <Row gutter={16} style={{ marginBottom: '16px', textAlign: 'right' }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                          <Button type="primary" icon={<SearchOutlined />} >
                            Search Report
                          </Button>
                          <Button type="default" icon={<FileExcelOutlined />} onClick={exportToExcel}>
                            Excel Download
                          </Button>
                        </Col>
                      </Row>
                      <Divider />
                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}
                dataSource={userList.map((user, index) => ({
                  key: (limit * (currentPage - 1)) + index + 1,
                  name: (
                    <>
                      <Avatar.Group>
                        <Avatar
                          className="shape-avatar"
                          shape="square"
                          size={40}
                          src={user.profile_img ? baseUrl + '/' + user.profile_img : userDummy}
                          icon={!user.profile_img && <UserOutlined />}
                          onClick={() => showModal(user.profile_img ? baseUrl + '/' + user.profile_img : userDummy)}
                          style={{ cursor: 'pointer' }}
                        />
                        <div className="avatar-info">
                          <Title level={5}>
                            <Link to={`/users/view/${user.id}`}  >
                              {user.name}
                            </Link>
                          </Title>

                        </div>
                      </Avatar.Group>{" "}
                    </>
                  ),
                  phone_no: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.phone_no}</Title>
                      </div>
                    </>
                  ),
                  father_name: (
                    <>
                      <div className="author-info">
                        <p>
                          {user.father_name}
                        </p>
                      </div>
                    </>
                  ),

                  dob_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.dob ? moment(user.dob).format('DD MMM') : '-'}  </Title>
                      </div>
                    </>
                  ),
                  dob: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.dob ? moment(user.dob).format('DD/MM/YYYY') : '-'}  </Title>
                      </div>
                    </>
                  ),

                  dob_response: user,

                  action: (
                    <div className="button-container">
                      <Tooltip title="Send message">
                      <Button className="primary"
                        icon={<SendOutlined />}
                        onClick={() => showConfirm(user.id)}
                      >
                        Send
                      </Button>
                      </Tooltip>
                    </div>
                  )

                }))}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>
    </div>
  );
}

export default BirthdayReport;
