import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, Table, Button, Input, notification, Modal, Select } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { getPermissions, hasPermission } from '../../components/permissions';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key"
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search Name`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search Description`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record.description.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
        title: "Status",
        key: "status",
        dataIndex: "status",
        // sorter: true,
        filters: [
            { text: 'Active', value: 'Active' },
            { text: 'Inactive', value: 'Inactive' }
        ],
        onFilter: (value, record) => record.status === value,
        render: (status) => (
            <span className={status === 'Active' ? 'text-success' : 'text-danger'}>
                {status === 'Active' ? 'Active' : 'Inactive'}
            </span>
        ),
    },
    {
        title: "Action",
        key: "action",
        dataIndex: "action",
    }
];

function RoleList() {
    const history = useHistory();
    const [userList, setUserList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        name: '',
        description: '',
        status: ''
    });

    useEffect(() => {
        const extraContent = hasPermission('readPermission', 'Read Role') ? (
            getUserList()
        ) : history.push('/dashboard');
    }, []);

    async function getUserList(page = 1, limit = 10, sortField = null, sortOrder = null, search = '', filters = {}) {
        try {
            setCurrentPage(page);
            setLimit(limit);
            const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
            const searchParam = search ? `&search=${search}` : '';
            const filterParams = Object.keys(filters)
                .map(key => `${key}=${filters[key]}`)
                .join('&');
            const response = await axios.get(`${baseUrl}/role/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.data.status) {
                setUserList(response.data.result);
                setPagination({
                    current: page,
                    pageSize: limit,
                    total: response.data.totalCount
                });
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    async function handleDelete(id) {
        try {
            const response = await axios.delete(`${baseUrl}/role/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            if (response.status === 200) {
                getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
                notification.success({
                    message: 'Success',
                    description: 'Role deleted successfully!',
                    placement: 'topRight'
                });
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(id);
            },
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
        setSortField(field);
        setSortOrder(formattedOrder);
        setFilters(filters);
        getUserList(current, pageSize, field, formattedOrder, search, filters);
    };

    const handleSearch = value => {
        setSearch(value);
        getUserList(currentPage, limit, sortField, sortOrder, value, filters);
    };

    const handleLimitChange = value => {
        setLimit(value);
        getUserList(1, value, sortField, sortOrder, search, filters);
    };

    const extraContent = hasPermission('createPermission', 'Create Role') ? (
        <Link className="custom-btn" to="/role/add">Add</Link>
    ) : null;

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title="Roles"
                        extra={hasPermission('createPermission', 'Create Role') && (
                            <Link className="custom-btn" to="/role/add">Add</Link>
                        )}
                    >
                        <div className="table-responsive">
                            <Table
                                title={() => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <span style={{ marginRight: 10 }}>Records per page:</span>
                                            <Select defaultValue={10} onChange={handleLimitChange}>
                                                <Option value={10}>10</Option>
                                                <Option value={20}>20</Option>
                                                <Option value={30}>30</Option>
                                                <Option value={40}>40</Option>
                                            </Select>
                                        </div>
                                        <Search
                                            placeholder="Search by name or description"
                                            onSearch={handleSearch}
                                            enterButton={<SearchOutlined />}
                                            style={{ width: 300 }}
                                        />
                                    </div>
                                )}
                                columns={columns}
                                dataSource={userList.map((user, index) => ({
                                    key: (limit * (currentPage - 1)) + index + 1,
                                    name: user.name,
                                    description: user.description,
                                    status: user.status,
                                    action: (
                                        <div className="button-container">
                                            {hasPermission('editPermission', 'Edit Role') && (
                                                <Link to={`/role/update/${user.id}`} className="update-btn">
                                                    <EditOutlined />
                                                </Link>
                                            )}
                                            {hasPermission('deletePermission', 'Delete Role') && (
                                                <button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                                                    <DeleteOutlined />
                                                </button>
                                            )}
                                        </div>
                                    )
                                }))}
                                pagination={{
                                    ...pagination,
                                    showTotal: total => `Total ${total} records`,
                                    style: { marginRight: 20 }
                                }}
                                onChange={handleTableChange}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default RoleList;
