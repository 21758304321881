// UserModalContent.js 
import React, { useState, useEffect } from 'react';
import { baseUrl } from "../../config";
import { Link } from "react-router-dom";
import moment from 'moment';
import { TableProps } from 'antd';
import axios from "axios";
import { getPermissions, hasPermission } from '../../components/permissions';

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  Space,
  Table,
  Tag,
  message, notification, Select, Typography, Form
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined, EditOutlined
} from "@ant-design/icons";

import BgProfile from "../../assets/images/bg-profile.jpg";
import profilavatar from "../../assets/images/face-1.jpg";
import { DescriptionsProps } from 'antd';

const { Title } = Typography;

const UserPaymentModalHistory = ({ userid }) => {



  const [imageURL, setImageURL] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [UserPaymentDetail, setUserPaymentDetail] = useState(false);
  const [UserPaymentOther, setUserPaymentOther] = useState({});

  useEffect(() => {
    setPaymentData([])
    console.log("ddddddd", userid);
    if (userid) {
      latestPayment();
      UserPaymentOverView();
      GetUserPaymentOther();
    }

  }, [userid]);

  const columns = [
    {
      title: 'SR. No',
      dataIndex: 'srNo',
      key: 'srNo',
      fixed: 'left',
      width: 10,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => `Rs. ${text}`,
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Account',
      dataIndex: 'bank_account',
      key: 'bank_account'
    },

    {
      title: 'Special Occasion Category',
      dataIndex: 'special_occassion_category',
      key: 'special_occassion_category',
    },
    {
      title: 'Start Month',
      dataIndex: 'start_month',
      key: 'start_month',
      render: (text) => text ? moment(text).format('MMM-YYYY') : '-',
    },
    {
      title: 'End Month',
      dataIndex: 'end_month',
      key: 'end_month',
      render: (text) => text ? moment(text).format('MMM-YYYY') : '-',
    },
 
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      render: (text) => text ? moment(text).format('DD-MMM-YYYY') : '-',
    },
    {
      title: 'Payment Verify',
      dataIndex: 'payment_virify',
      key: 'payment_virify',
      fixed: 'right',
      render: (text) => {
        const statusColors = {
          verified: 'green',
          failed: 'red',
          pending: 'orange',
        };

        return <Tag color={statusColors[text] || 'blue'}>{text.toUpperCase()}</Tag>;
      }
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      fixed: 'right',
      render: (text, payment) => (
        <div className="button-container">
          {hasPermission('editPermission', 'Edit Payment') && (
            <Link to={`/payments/update/${payment.payment_id}`} className="update-btn">
              <EditOutlined />
            </Link>
          )}
        </div>
      ),
    }
  ];

  const UserPaymentOverView = async () => {
    try {
      const response = await axios.get(`${baseUrl}/payments/UserPaymentOverView/${userid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {  
        const { PaymentSN } = response.data.result;
        setUserPaymentDetail(PaymentSN);  
      }  
    } catch (error) {
      console.error("API error:", error); 
    }
  };
  const GetUserPaymentOther = async () => {
    try {
      const response = await axios.get(`${baseUrl}/payments/UserPaymentOther/${userid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {  
        const { groupedPayments } = response.data.result; 
        setUserPaymentOther(groupedPayments);  
      }  
    } catch (error) {
      console.error("API error:", error); 
    }
  };

  const latestPayment = async () => {
    try {
      setPaymentData([])
      const response = await axios.get(`${baseUrl}/payments/latestUserPayment/${userid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        // setRoleData(response.data.result);
        const resultData = response.data.result;

        const indexedData = resultData.map((item, index) => ({ ...item, srNo: index + 1 }));
        setPaymentData(indexedData);



      } else {
        // notification.info({
        //   message: 'Info',
        //   description: response.data.message,
        //   placement: 'topRight'
        // });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const { total_pledge_amount, received_amount } = UserPaymentDetail;

  const renderDueOrAdvanceAmount = () => {
      if (received_amount > total_pledge_amount) {
          const advanceAmount = received_amount - total_pledge_amount;
          return <Descriptions.Item label="Advance Amount"> Rs.{advanceAmount} </Descriptions.Item>;
      } else {
          const dueAmount = total_pledge_amount - received_amount;
          return <Descriptions.Item label="Due Amount"> Rs.{dueAmount} </Descriptions.Item>;
      }
  };

  return (

    
    <>

      <Row gutter={[24, 0]}>

        <Col span={24} md={24} className="mb-24">
          {UserPaymentDetail && (
            <>
              <Descriptions size="default" bordered >
                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold'}}>Account</span>}>{UserPaymentDetail.bank_account} </Descriptions.Item>
                <Descriptions.Item label="Joininig/Ann. Date">
                  {UserPaymentDetail.joining_date ? moment(UserPaymentDetail.joining_date).format('DD-MMM-YYYY') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Monthly Share"> Rs.{UserPaymentDetail.announced_amount}  </Descriptions.Item>
              
                <Descriptions.Item label="Amount Pledge"> Rs.{UserPaymentDetail.total_pledge_amount}  </Descriptions.Item>
                <Descriptions.Item label="Payment/Txn date(First)">
                  {UserPaymentDetail.first_payment_date ? moment(UserPaymentDetail.first_payment_date).format('DD-MMM-YYYY') : ''}

                </Descriptions.Item>
                <Descriptions.Item label="Duration ( Total)">{UserPaymentDetail.total_duration} </Descriptions.Item>
                <Descriptions.Item label="Received Amount"> Rs.{UserPaymentDetail.received_amount}  </Descriptions.Item>
                <Descriptions.Item label="Remark"> {UserPaymentDetail.remarks}  </Descriptions.Item>
                <Descriptions.Item label="Announced for(yr)">{UserPaymentDetail.status}</Descriptions.Item>
                {renderDueOrAdvanceAmount()}
               
              </Descriptions>
            </>
          )}

          {Object.keys(UserPaymentOther).length > 0 && (
            <>
              {Object.keys(UserPaymentOther).map((accountId) => (
                <div key={accountId}>
                  <Descriptions size="default" bordered    >
                    <Descriptions.Item  label={<span style={{ fontWeight: 'bold',width: '30%', display: 'inline-block'}}>Account</span>}>
                      {UserPaymentOther[accountId].bank_account_name}
                    </Descriptions.Item>
                  </Descriptions>
                  {UserPaymentOther[accountId].details.map((payment, index) => (
                    <Descriptions size="default" bordered key={index}>
                      <Descriptions.Item label="Special Occasion">
                        {payment.special_occassion_category}
                      </Descriptions.Item>
                      <Descriptions.Item label=" Received Amount">
                        Rs.{payment.total_amount}
                      </Descriptions.Item>
                    </Descriptions>
                  ))}
                </div>
              ))}
            </>
          )}

        </Col>

      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24}><Title level={5}>Payment History</Title> </Col>

        <Col span={24} md={24} className="mb-24">
          <Card>
            <Table
              scroll={{ x: 'max-content' }}
              bordered
              columns={columns}
              pagination={false}
              dataSource={paymentData}
              rowKey="transaction_date" />
          </Card>
        </Col>

      </Row>

    </>
  );
};

export default UserPaymentModalHistory;